import React, { useState } from "react";
import "../../Styles/SandalwoodCsr.css";
import servicespage1 from "../.././Assets/CSR.jpg";
import leaf from "../.././Assets/gift-vector-leaf.png";
import bookFlipPdf from "../.././Assets/Services-booklet.pdf";
import Layout from "../Layout/Layout";
import Footer from "../Layout/Footer";
import { Container, Row } from "react-bootstrap";

const SandalwoodCsr = () => {
  const [cartItems, setCartItems] = useState([]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf;
    link.download = "Services-booklet.pdf";
    link.click();
  };

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="Csr-container">
        <div className="container">
          <div className="Csr-text">
            <span className="Csr-text1">Corporate</span>
            <span className="Csr-text2">Social Responsibility</span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="Csr-info-content-wrapper">
          <img src={leaf} alt="leaf" className="Csr-leaf" />
          <Row>
            <div className="col-lg-7 col-sm-12 col-12 p-2">
              <div className="Csr-info-content">
                <h2>CSR</h2>
                <p>
                  Sandalwood CSR (Corporate Social Responsibility) initiatives focus
                  on promoting sustainable practices within the sandalwood industry
                  while benefiting local communities and the environment. These
                  initiatives often include reforestation efforts,community
                  education programs on sustainable cultivation, and support for
                  local economies through fair trade practices. By prioritizing
                  social and environmental responsibility, sandalwood CSR aims to
                  foster a balance between economic growth and ecological
                  preservation, ensuring that sandalwood resources are managed
                  sustainably for future generations while positively impacting the
                  communities involved in its cultivation.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12 col-12 p-2">
              <img
                src={servicespage1}
                alt="Right Image"
                className="img-fluid"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </div>
          </Row>
        </div>
      </div>

      <section className="mb-80">
        <Container>
          <div className="farmland-info-bottom">
            <div className="farmland-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button
              className="learn-more-button"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
        </Container>
      </section>

      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default SandalwoodCsr;

import React from "react";
import CountUp from "react-countup";
import experienceIcon from "../../Assets/icon_experience1.png";
import plantationIcon from "../../Assets/icon_Acres_plantation2.png";
import plantsIcon from "../../Assets/icon_Trees_planted4.png";
import trainingIcon from "../../Assets/icon _Trained3.png";
import "./FeaturesArea.css";

function FeaturesArea() {
  return (
    <>
      <section className="features-area">
        <div className="container-fluid">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="white">
              <h2 className="features-title">
                Our Sandalwood Remarkable Accomplishments
              </h2>
            </div>
          </div>
          <div className="row ">
            {/* Sandalwood Experience */}
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="Icon">
                    <img
                      src={process.env.PUBLIC_URL + experienceIcon}
                      alt="Sandalwood Experience Icon"
                    />
                  </div>
                  <CountUp end={30} delay={3} duration={5} /> <sup>+</sup>
                  <h4 className="feature-heading">
                    Years of Sandalwood Experience
                  </h4>
                </div>
              </div>
            </div>

            {/* Sandalwood Plantations Covered */}
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="Icon">
                    <img
                      src={process.env.PUBLIC_URL + plantationIcon}
                      alt="Plantation Icon"
                    />
                  </div>
                  <CountUp end={20000} delay={3} duration={5} /> <sup>+</sup>
                  <h4 className="feature-heading">
                    Acres of Sandalwood Plantations Covered
                  </h4>
                </div>
              </div>
            </div>

            {/* Sandalwood Plants */}
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="Icon">
                    <img
                      src={process.env.PUBLIC_URL + plantsIcon}
                      alt="Sandalwood Plants Icon"
                    />
                  </div>
                  <CountUp end={500000} delay={3} duration={5} /> <sup>+</sup>
                  <h4 className="feature-heading">Sandalwood Plants</h4>
                </div>
              </div>
            </div>

            {/* Individuals Trained */}
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="800ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="Icon">
                    <img
                      src={process.env.PUBLIC_URL + trainingIcon}
                      alt="Training Icon"
                    />
                  </div>
                  <CountUp end={40000} delay={3} duration={5} /> <sup>+</sup>
                  <h4 className="feature-heading">Individuals Trained</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeaturesArea;

import React, { useState } from "react";
import axios from "axios";
import Layout from "./../Components/Layout/Layout";
import "../Styles/ContactWrapper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faYoutube, } from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Footer from "../Components/Layout/Footer";
import { Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from "@mui/material";  
import OutlinedInput from '@mui/material/OutlinedInput';

function ContactWrapper() {
  const [cartItems, setCartItems] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [interest, setInterest] = useState("");
  const [message, setMessage] = useState("");
  const [agreed, setAgreed] = useState(false);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [interestError, setInterestError] = useState("");
  const [agreedError, setAgreedError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const states = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana",
    "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana",
    "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi", "Puducherry", "Ladakh", "Jammu and Kashmir",
  ];

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
  const phoneRegex = /^[6-9]\d{9}$/; 

  const handleSubmit = async (e) => {
    e.preventDefault();

    setNameError("");
    setEmailError("");
    setPhoneError("");
    setLocationError("");
    setInterestError("");
    setAgreedError("");

    let isValid = true;

    if (!name) {
      setNameError("Please enter your name.");
      isValid = false;
    }
    if (!email) {
      setEmailError("Please enter your email.");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    }
    if (!phone) {
      setPhoneError("Please enter your phone number.");
      isValid = false;
    } else if (!phoneRegex.test(phone)) {
      setPhoneError("Please enter a valid 10-digit phone number starting with 6-9.");
      isValid = false;
    } else if (phone.length !== 10) { 
      setPhoneError("Phone number must be exactly 10 digits.");
      isValid = false;
    }
    if (!location) {
      setLocationError("Please select your location.");
      isValid = false;
    }
    if (!interest) {
      setInterestError("Please select an area of interest.");
      isValid = false;
    }
    if (!agreed) {
      setAgreedError("You must authorize to contact us.");
      isValid = false;
    }

    if (!isValid) return;

    const payload = {
      name,
      email_id: email,
      phone: phone,
      location,
      interest_area: interest,
      message,
    };

    try {
      const response = await axios.post(
        'https://rispl.backendapihub.com/contactUs/add',
        payload,
      );

      if (response.status === 200) {
        setSuccessMessage("Your message has been sent successfully!");
         setName("");
        setEmail("");
        setPhone("");
        setLocation("");
        setInterest("");
        setMessage("");
        setAgreed(false);
      }
    } catch (error) {
      setSuccessMessage("There was an error sending your message. Please try again.");
    }
  };
  
  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="contact-bg row position-relative"></div>
      <section className="contact-area sec-mar">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="contact-left">
                <div className="sec-title layout2">
                  <span>Get in touch</span>
                  <h2>Contact us</h2>
                </div>
                <ul className="social-follow">
                  <li>
                    <a href="https://www.facebook.com">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
                <div className="informations">
                  <div className="single-info">
                    <div className="iconn" style={{ width: "77px" }}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </div>
                    <div className="infoo">
                      {/* <h3>Address 1</h3> */}
                      <p>
                        Royal Indian Sandalwood Private Limited. <br />
                        MSR Boys Hostel, 6, 7th Main Rd, <br />M S Ramaiah
                        Nagar, Mathikere, Bengaluru – 560054, Karnataka, INDIA.
                      </p>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="iconn">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </div>
                    <div className="infoo">
                      {/* <h3>Address 2</h3> */}
                      <p>
                        Ward 9. Block 65 2, Near Jindhal Srikanthapura, <br />
                        Bengaluru - 560073, Karnataka, INDIA.
                      </p>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="iconn">
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    </div>
                    <div className="infoo">
                      {/* <h3>Phone</h3> */}
                      <a href="tel:+919004013140">+91 9004013140</a>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="iconn">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <div className="infoo">
                      {/* <h3>Email</h3> */}
                      <a href="mailto:info@royalindiansandalwood.com">
                        info@royalindiansandalwood.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <div className="mapouter">
                {/* Map embed */}
                <div className="gmap_canvas">
                  <iframe
                    width="100%"
                    height="100%"
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d496.1326058652933!2d77.5636413917614!3d13.032871131897101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17d6845fe043%3A0xdcffb3b4556c4347!2sROYAL%20INDIAN%20SANDALWOOD%20--%20Natural%20Sandalwood%20products%20%7C%20Sandalwood%20services%20(Plantation%2C%20Maintenance%2C%20Training%20and%20CSR).!5e0!3m2!1sen!2sin!4v1733806187647!5m2!1sen!2sin"                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  />
                </div>
              </div>
            </div>

            {/* Contact form */}
            <div className="col-md-6 col-lg-7">
              <div className="getin-touch">
                <div className="contact-form wow animate fadeInUp">
                  <h3>Let's Connect</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <input
                          className="in-text"
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                        {nameError && <p className="error-message" style={{ color: "red" }}>{nameError}</p>}
                      </div>

                      <div className="col-md-6">
                        <input
                          className="in-text"
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          required
                        />
                        {emailError && <p className="error-message" style={{ color: "red" }}>{emailError}</p>}
                      </div>

                      <div className="col-md-6">
                        <input
                          className="in-text"
                          type="tel"
                          name="phone"
                          placeholder="Enter your phone number"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                          required
                        />
                        {phoneError && <p className="error-message" style={{ color: "red" }}>{phoneError}</p>}
                      </div>

                      {/* Location Dropdown - MUI Select */}
                      <div className="col-md-6">
                        <FormControl fullWidth required>
                          <InputLabel id="location-label" sx={{'&.Mui-focused': {color: '#EDBD67'},}}>Select your location</InputLabel>
                          <Select
                            labelId="location-label"
                            id="location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            input={<OutlinedInput label="Select your location" />}
                            sx={{
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#EDBD67',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#EDBD67', 
                              },
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E9E9E9', 
                              },
                            }}
                          >
                            {states.map((state, index) => (
                              <MenuItem key={index} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {locationError && <p className="error-message" style={{ color: "red" }}>{locationError}</p>}
                      </div>

                      {/* Interest Dropdown - MUI Select */}
                      <div className="col-md-6">
                        <FormControl fullWidth required>
                          <InputLabel id="interest-label" sx={{
                          '&.Mui-focused': {
                           color: '#EDBD67', 
                          },}}>Area of Interest</InputLabel>
                          <Select
                            labelId="interest-label"
                            id="interest"
                            value={interest}
                            onChange={(e) => setInterest(e.target.value)}
                            input={<OutlinedInput label="Area of Interest" />}
                            sx={{
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#EDBD67',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#EDBD67', 
                              },
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E9E9E9',
                              },
                            }}
                          >
                            <MenuItem value="Plantation">Plantation</MenuItem>
                            <MenuItem value="Har Ghar Chandan">Har Ghar Chandan</MenuItem>
                            <MenuItem value="Training">Training</MenuItem>
                            <MenuItem value="Unique Gifting">Unique Gifting</MenuItem>
                            <MenuItem value="Gift a plant">Gift a Plant</MenuItem>
                            <MenuItem value="Products">Products</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </Select>
                        </FormControl>
                        {interestError && <p className="error-message" style={{ color: "red" }}>{interestError}</p>}
                      </div>

                      <div className="col-12">
                        <textarea
                          className="in-text mess"
                          name="message"
                          placeholder="Your message"
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                        ></textarea>
                      </div>

                      <div className="col-12">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={agreed}
                              onChange={(e) => setAgreed(e.target.checked)}
                              color="primary"
                              sx={{
                                color: '#EDBD67', 
                                '&.Mui-checked': {
                                  color: '#EDBD67', 
                                },
                              }}
                            />
                          }
                          label="I Authorise Royal Indian Sandalwood Private Limited Representative To Contact Me Via SMS, Call Or Whatsapp."
                        />
                        {agreedError && <p className="error-message" style={{ color: "red" }}>{agreedError}</p>}
                      </div>

                      <div className="col-12">
                        <button className="theme-btn" type="submit">Submit</button>
                      </div>

                      {successMessage && <p className="success-message">{successMessage}</p>}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
}


export default ContactWrapper;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import "./About.css";
import "../../Styles/AboutPage.css";
import Our_Core_Team from "../../Assets/Our_Core_Team.png";
import exclusive_img2 from "../../Assets/aboutimg2.png";
import exclusive_img3 from "../../Assets/about-btn.png";
import exclusive_img4 from "../../Assets/about-flower1.png";
import exclusive_img5 from "../../Assets/about-flower2.png";
import { Container } from "react-bootstrap";
// import RISPL_3 from "../../Assets/RISPL_3.mp4";

export const About = ({ showAboutMainpg }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  {
    isModalOpen && console.log("Modal should be visible");
  }

  const openModal = () => {
    console.log("Modal opening...");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };

    const handleClickOutside = event => {
      if (
        !event.target.closest(".about-circle") &&
        !event.target.closest(".about-circle2")
      ) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div>
        {showAboutMainpg ? (
          <>
            <div className="about-main1" style={{ marginTop: 200 }}>
              <img className="abt-img1" src={exclusive_img4} alt="" />
              <Container className="about aboutst">
                <div className="about-left">
                  <h1>Who we are?</h1>
                  <p>
                    As a pioneering Indian company, we specialize in
                    eco-friendly Indian sandalwood services and products. Our
                    team, composed of sandalwood industry veteran scientists
                    with over 30 years of experience, has elevated sandalwood
                    cultivation to new heights. Our commitment to excellence and
                    quality ensures only the finest Indian sandalwood, from seed
                    selection to plantation management. Ethics and integrity are
                    at the core of our operations, fostering trust and long-term
                    relationships with our customers. We are the reliable source
                    for premium sandalwood.
                  </p>
                  <img src={exclusive_img2} alt="" />
                </div>
                <div className="gpgpgpg">
                  <div className="about-right">
                    <img src={Our_Core_Team} alt="" />
                    <div className="about-circle" onClick={openModal}>
                      <img
                        src={exclusive_img3}
                        alt="playbtn"
                        style={{
                          cursor: "pointer",
                          width: "46.67px",
                          height: "45.83px",
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="about-ring-three">
                    <div className="about-ring-two">
                      <div className="about-ring-one"></div>
                    </div>
                  </div> */}
                </div>
              </Container>
              {/* <img className="abt-img2" src={exclusive_img5} alt="" /> */}
              <img className="img-fluid gg" src={exclusive_img5} alt="" />
            </div>
          </>
        ) : (
          <div className="about-main2">
            <div className="aboutbg position-absolute"></div>

            <img className="Abt-img1" src={exclusive_img4} alt="" />
            <img className="Abt-img2" src={exclusive_img5} alt="" />
            <Container className="about2 aboutst">
              <div className="about-left2">
                <h1>Who we are?</h1>
                <p>
                  India's first private limited company providing end-to-end
                  solutions in the field of <b>“INDIAN SANDALWOOD”</b> (Chandan
                  / Santalum Album / Srigandha).
                </p>
                <p>
                  Sandalwood industry veteran scientists who have spent over 30
                  years in planting, nurturing, studying, researching, and
                  documenting the complete lifecycle of sandalwood.
                </p>
                <p>
                  A company striving for excellence through quality, commitment,
                  communication, and loyalty towards our customers.
                </p>
                <p>A team trusted for its ethics, integrity, and aptitude.</p>
                <img src={exclusive_img2} alt="" />
              </div>
              <div className="gpgpgpg">
                <div className="about-right">
                  <img src={Our_Core_Team} alt="" />
                  <div
                    className="about-circle2"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <img
                      src={exclusive_img3}
                      alt="playbtn"
                      style={{
                        cursor: "pointer",
                        width: "46.67px",
                        height: "45.83px",
                      }}
                    />
                  </div>
                </div>
                {/* <div className="about-ring-three">
                  <div className="about-ring-two">
                    <div className="about-ring-one"></div>
                  </div>
                </div> */}
              </div>
            </Container>
          </div>
        )}

        {isModalOpen && (
          <div className="modal-overlay-about">
            <div className="modal-content-about">
              <video autoPlay controls className="video-element-about">
                {/* <source src={RISPL_3} type="video/mp4" />  */}
              </video>
              <button className="Close-btn-about" onClick={closeModal}>
                <FontAwesomeIcon
                  icon={faWindowClose}
                  className="Close-button-about"
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default About;

import React, { useState } from "react";
import "../../Styles/SandalwoodConsultancy.css";
import servicespage1 from "../.././Assets/Sandalwood_consultancy.jpg";
import leaf from "../.././Assets/gift-vector-leaf.png";
import bookFlipPdf from "../.././Assets/Services-booklet.pdf";
import Layout from "../Layout/Layout";
import Footer from "../Layout/Footer";
import { Container, Row } from "react-bootstrap";

const SandalwoodConsultancy = () => {
  const [cartItems, setCartItems] = useState([]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf; 
    link.download = "Services-booklet.pdf";
    link.click();
  };

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="Consultancy-container">
        <div className="container">
          <div className="Consultancy-text">
            <span className="Consultancy-text1">Consultancy</span>{" "}
            {/* <span className="Consultancy-text2">Sandalwood Plantation</span> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="Consultancy-info-content-wrapper">
          <img src={leaf} alt="leaf" className="Consultancy-leaf" />
          <Row>
            <div className="col-lg-7 col-sm-12 col-12 p-2">
              <div className="Consultancy-info-content">
                <h2>Consultancy</h2>
                <p>
                  Sandalwood consultancy offers specialized guidance to individuals
                  and businesses involved in sandalwood cultivation and management.
                  Our services include strategic planning, soil and site assessment,
                  best practices for planting and maintenance, pest control
                  recommendations, and sustainable harvesting techniques. With a
                  focus on maximizing yield and ensuring environmental
                  sustainability, our consultancy aims to empower clients with the
                  knowledge and tools needed for successful sandalwood production.
                  By leveraging industry expertise, we help clients navigate the
                  complexities of sandalwood cultivation and enhance their overall
                  investment in this valuable resource.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12 col-12 p-2">
              <img
                src={servicespage1}
                alt="Right Image"
                className="img-fluid"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </div>
          </Row>
        </div>
      </div>

      <section className="mb-80">
        <Container>
          <div className="farmland-info-bottom">
            <div className="farmland-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button
              className="learn-more-button"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
        </Container>
      </section>

      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default SandalwoodConsultancy;

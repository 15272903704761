import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/AboutPage";
import ServicePage from "./Pages/ServicePage";
// import HandcraftsPage from "./Pages/HandcraftsPage";
import Gifting from "./Pages/Gifting";
import Login from "./Pages/Auth/Login";
import Dashboard from "./Pages/User/Dashboard";
import Orders from "./Pages/User/Orders";
import ProductDetails from "./Pages/ProductDetails";
import Categories from "./Pages/Categories";
import CartPage from "./Pages/CartPage";
import CategoryProduct from "./Pages/CategoryProduct";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Wishlist from "./Pages/User/Wishlist";
import ProductPage from "./Pages/ProductList";
import { Toaster } from "react-hot-toast";
import CheckoutPage from "./Pages/CheckoutPage";
import Productpagemain from "./Pages/Productpagemain";
import PlantationServices from "./Components/Sub_Home/PlantationService";
import Farmland from "./Components/Sub_Home/Farmland";
import Villa from "./Components/Sub_Home/Villa";
import Training from "./Components/Sub_Home/Training";
import PaymentFailurePage from "./Pages/PaymentFailurePage";
import PaymentSuccessPage from "./Pages/PaymentSuccessPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ReturnRefundPolicy from "./Pages/ReturnRefundPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import SandalwoodFarmlandPlantation from "./Components/Sub_Home/SandalwoodFarmlandPlantation";
import SandalwoodMaintenance from "./Components/Sub_Home/SandalwoodMaintenance";
import SandalwoodHeartwoodAssessment from "./Components/Sub_Home/SandalwoodHeartwoodAssessment";
import SandalwoodConsultancy from "./Components/Sub_Home/SandalwoodConsultancy";
import SandalwoodCsr from "./Components/Sub_Home/SandalwoodCsr";
import ContactWrapper from "./Pages/ContactWrapper";
import BookFlip from "./Components/Sub_Home/BookFlip";
import OrderConfirmedPage from "./Pages/OrderConfirmedPageCod";

function App() {
  return (
    <>
        <Routes>  
          <Route path="/" element={<HomePage />} />
          <Route path="/PaymentSuccess" element={<PaymentSuccessPage />} />
          <Route path="/PaymentFailure" element={<PaymentFailurePage />} />
          <Route path="/order-confirmed" element={<OrderConfirmedPage />} />
          <Route path="/products" element={<Productpagemain />} />
          <Route path="/PlantationServices" element={<PlantationServices />} />
          <Route path="/FarmlandPlantation" element={<SandalwoodFarmlandPlantation />} />
          <Route path="/Maintenance" element={<SandalwoodMaintenance />} />
          <Route path="/HeartwoodAssessment" element={<SandalwoodHeartwoodAssessment />} />
          <Route path="/Consultancy" element={<SandalwoodConsultancy />} />
          <Route path="/CSR" element={<SandalwoodCsr />} />
          <Route path="/SandalwoodPlantation" element={<Farmland />} />
          <Route path="/Villa_Bunglow" element={<Villa />} />
          <Route path="/Training_Modules" element={<Training />} />
          <Route path="/productlist" element={<ProductPage />} />
          <Route path="/product/:slug" element={<ProductDetails />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/bookflip" element={<BookFlip />} />
          <Route path="/category/:slug" element={<CategoryProduct />} />

          <Route
            path="/dashboard"
            // element={<PrivateRoute />}
          >
            <Route path="user/profile" element={<Dashboard />} />
            <Route path="user/orderhistory" element={<Orders />} />
            <Route path="user/wishlist" element={<Wishlist />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/Services" element={<ServicePage />} />
          <Route path="/services" element={<Categories />} />
          {/* <Route path="/handcraft" element={<HandcraftsPage />} /> */}
          <Route path="/gifting" element={<Gifting />} />
          <Route path="/Contact" element={<ContactWrapper />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/Return-Refund-policy" element={<ReturnRefundPolicy/>} />
          <Route path="/Terms-Conditions-policy" element={<TermsAndConditions/>} />
        </Routes>
      <Toaster />
    </>
  );
}

export default App;

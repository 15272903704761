import React, { useState } from "react";
import "../.././Styles/ServicesPage.css";
import ServiceImage from "../../Assets/service_image.png";
import bookFlipPdf from "../../Assets/Services-booklet.pdf";
import { useNavigate } from "react-router-dom";

const ServicesPage = () => {
  const navigate = useNavigate();

  const handleNavigate = path => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf;
    link.download = "Services-booklet.pdf";
    link.click();
  };

  return (
    <>
      <div className="servicebg row position-relative"></div>
      <section className="service_info">
        <div className="container">
          <div className="services-container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                <h1 className="services-title">Services we offer</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <p className="services-description">
                  As a pioneering Indian company, we specialize in eco-friendly
                  Indian sandalwood services and products. Our team, composed of
                  sandalwood industry veteran scientists with over 30 years of
                  experience, has elevated sandalwood cultivation to new
                  heights. Our commitment to excellence and quality ensures only
                  the finest Indian sandalwood, from seed selection to
                  plantation management. Ethics and integrity are at the core of
                  our operations, fostering trust and long-term relationships
                  with our customers. We are the reliable source for premium
                  sandalwood.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="t-box">
        <div className="first-circle">
          <h3 className="circle-title">The Circle of Sandalwood Services</h3>
          <div className="second-circle">
            <span className="third-circle"></span>
            <span className="four-circle">
              <img className="tree-four" src={Tree} alt="Tree" />
            </span>
          </div>
          <div className="top-box" onClick={handle_click_1}>
            <span className="dot-1"></span>
            <div className="number" style={{ top: "25px", left: "7px" }}>
              1
            </div>
            <h2 style={{ paddingTop: "40px", paddingLeft: "60px" }}>
              Plantation
            </h2>
          </div>
          <div className="left-box" onClick={handle_click_2}>
            <span className="dot-2"></span>
            <div className="number" style={{ top: "25px" }}>
              6
            </div>
            <h2 style={{ paddingTop: "45px" }}>CSR</h2>
          </div>
          <div className="right-box" onClick={handle_click_3}>
            <span className="dot-3"></span>
            <div className="number" style={{ top: "25px" }}>
              4
            </div>
            <h2 style={{ paddingTop: "45px" }}>Training</h2>
          </div>
          <div className="bottom-box" onClick={handle_click_4}>
            <span className="dot-4"></span>
            <div className="number" style={{ top: "30px", left: "200px" }}>
              5
            </div>
            <h2 style={{ paddingTop: "45px", paddingRight: "50px" }}>
              Consultancy
            </h2>
          </div>
          <div className="right-box consultancy-box" onClick={handle_click_5}>
            <span className="dot-5"></span>
            <div className="number" style={{ top: "25px" }}>
              3
            </div>
            <h2
              style={{
                paddingTop: "25px",
                paddingLeft: "83px",
                width: "228.03px",
              }}
            >
              Heart wood assessment
            </h2>
          </div>
          <div className="right-box csr-box" onClick={handle_click_6}>
            <span className="dot-6"></span>
            <div className="number" style={{ top: "25px" }}>
              2
            </div>
            <h2 style={{ paddingTop: "45px", paddingLeft: "45px" }}>
              Maintenance
            </h2>
          </div>
        </div>
      </div> */}
      <section className="service_main_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10">
              <h3 className="circle-title">
                The Circle of Sandalwood Services
              </h3>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <div
                    class="top-box mb-2"
                    onClick={() => handleNavigate("/SandalwoodPlantation")}
                  >
                    <div class="number">1</div>
                    <h2>Plantation</h2>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                  <div className="row h-100">
                    <div className="col-12">
                      <div
                        class="top-box mb-5 mt-2 d-md-flex d-none"
                        onClick={() => handleNavigate("/Consultancy")}
                      >
                        <div class="number">6</div>
                        <h2>Consultancy</h2>
                      </div>
                      <div
                        class="top-box mb-3 mt-2 d-sm-flex d-md-none d-flex"
                        onClick={() => handleNavigate("/Maintenance")}
                      >
                        <div class="number">2</div>
                        <h2>Maintenance</h2>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        class="top-box d-md-flex d-none"
                        onClick={() => handleNavigate("/Csr")}
                      >
                        <div class="number">5</div>
                        <h2>CSR</h2>
                      </div>
                      <div
                        class="top-box mb-5 mt-2 d-sm-flex d-md-none d-flex"
                        onClick={() => handleNavigate("/HeartwoodAssessment")}
                      >
                        <div class="number">3</div>
                        <h2>Heart wood assessment</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 p-3 col-md-4 col-sm-12 col-12">
                  <img
                    src={ServiceImage}
                    className="img-fluid"
                    alt="service-image"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                  <div className="row h-100">
                    <div className="col-12">
                      <div
                        class="top-box mb-5 mt-2 d-md-flex d-none"
                        onClick={() => handleNavigate("/Maintenance")}
                      >
                        <div class="number">2</div>
                        <h2>Maintenance</h2>
                      </div>
                      <div
                        class="top-box  mb-3 mt-2 d-sm-flex d-md-none d-flex"
                        onClick={() => handleNavigate("/Training_Modules")}
                      >
                        <div class="number">4</div>
                        <h2>Training</h2>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        class="top-box d-md-flex d-none"
                        onClick={() => handleNavigate("/HeartwoodAssessment")}
                      >
                        <div class="number">3</div>
                        <h2>Heart wood assessment</h2>
                      </div>

                      <div
                        class="top-box mb-3 mt-2 d-sm-flex d-md-none d-flex"
                        onClick={() => handleNavigate("/Consultancy")}
                      >
                        <div class="number">5</div>
                        <h2>Consultancy</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <div
                    class="top-box mt-2 d-md-flex d-none"
                    onClick={() => handleNavigate("/Training_Modules")}
                  >
                    <div class="number">4</div>
                    <h2>Training</h2>
                  </div>
                  <div
                    class="top-box d-md-none d-flex"
                    onClick={() => handleNavigate("/Csr")}
                  >
                    <div class="number">6</div>
                    <h2>CSR</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "-30px", marginBottom:"60px" }}>
        <div className="container">
          <div className="service-info-bottom">
            <div className="service-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button
              className="service-learn-more-button"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesPage;

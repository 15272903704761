import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import Footer from "./Footer";

const Layout = ({
  children,
  title,
  description,
  keywords,
  author,
  cartItems,
  setCartItems,
  isFooter,
  isNavbar,
  textColor
}) => {

  const calculateTotalQuantity = () => {
    if (Array.isArray(cartItems)) {
      return cartItems.reduce((total, item) => total + (item?.qty || 0), 0);
    }
    return 0;
  };
  
  const accessToken = sessionStorage.getItem("auth");

  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        "https://rispl.backendapihub.com/cart/show",
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          setCartItems(response.data.data);
        } else {
          // console.error("Cart items data is not an array:", response.data.data);
          setCartItems([]);
        }
      } else {
        console.error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setCartItems && setCartItems([]);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  return (
    <div>
      {/* ...SEO friendly...*/}
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>

      {isNavbar && (
        <Navbar showNormalNav={true} cartCount={calculateTotalQuantity()} textColor={textColor} />
      )}
      <main >
        <Toaster />
        {children}
        {isFooter && <Footer showBothDivs={true} />}
      </main>
    </div>
  );
};

Layout.defaultProps = {
  title: "Sandalwood",
  description: "Sandalwood-Har Ghar Chandan",
  keywords: "facial-care, body-care, hair-care",
  author: "@Sandalwood",
  textColor: "black"
};

export default Layout;

import React, { useState } from "react";
import "./BookFlip.css";
import Footer_logo from "../../Assets/Footerlogo.png";
import logo from "../../Assets/logo.png";
export const BookCover = () => {
  return (
    // <div className="container">
    <div className="book">
      <div className="BookCover-left">
        <div className="left-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="main-text">
          <div>
            <h6 style={{ marginTop: "5px" }}>
              <b>OFFICE ADDRESS</b>
            </h6>
          </div>
          <div>
            <p style={{fontSize:"10px"}}>
              <b>office 1:</b> Ward 9, Block 65 2, Near Jindhal
              Srikanthapura,Bengaluru 560073, Karnataka, India
            </p>
            <p style={{fontSize:"10px"}}>
              <b>office 2:</b> 7/1, MSR Boys Home, MSREC Road,Bengaluru 560054,
              Karnataka ,India
            </p>
            <p style={{fontSize:"10px"}}>
              Website: www.royalindiandalwood.com, E-mail:
              info@royalindiandalwood .com, Contact: +91 9004013140
            </p>
          </div>
          <div>
            <h6 style={{ marginTop: "20px" }}>
              <b>CONTACT NUMBERS</b>
            </h6>
          </div>
          <div>
            <p>Bengaluru</p>
            <p>
              Dr.K.T Chandrashekhar: +91 9945994777, G.Ravikumar: +91 9740984131
            </p>
            <p>Mumbai:</p>
            <p>Rajan Shetty: +91 9324025700</p>
          </div>
        </div>
      </div>
      <div className="BookCover-right">
        <div className="right-logo">
          <img src={Footer_logo} alt="logo" />
        </div>
      </div>
    </div>
    //  </div>
  );
};
export default BookCover;

import React, { useState } from "react";
import "../../Styles/Training.css";
import Training_2 from "../../Assets/Training_2.jpg";
import training_img1 from "../../Assets/training_img1.png";
import leaf from "../.././Assets/gift-vector-leaf.png";
import bookFlipPdf from "../.././Assets/Services-booklet.pdf";
import Layout from "../../Components/Layout/Layout";
import Footer from "../../Components/Layout/Footer";
import { Col, Container, Row } from "react-bootstrap";
// import trainingVideo from "../../Assets/Training_video.mp4"; 

const Training = () => {
  const [cartItems, setCartItems] = useState([]);
  const [showVideo, setShowVideo] = useState(false);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf;
    link.download = "Services-booklet.pdf";
    link.click();
  };

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="Training-section">
        <div className="container">
          <div className="Training-text-overlay">
            <span className="Training-textt-1">Training</span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="Training-details-content">
          <img src={leaf} alt="leaf" className="Heartwood-leaf" />
          <Row>
            <Col className="col-lg-7 col-sm-12 col-12 p-2">
              <div className="Training-details-text">
                <h2>Training</h2>
                <p>
                  The Sandalwood Training Program aims to enhance participants'
                  knowledge and skills in sandalwood cultivation and management.
                  Covering essential topics such as planting techniques, maintenance
                  practices, pest control, and sustainable harvesting methods.
                  <br />
                  This training is conducted by industry experts with over 30 years
                  of experience. It provides a valuable opportunity for all
                  sandalwood enthusiasts to deepen their understanding of sandalwood
                  and contribute to effective resource management.
                </p>
              </div>
            </Col>
            <Col className="col-lg-5 col-sm-12 col-12 p-2">
              <div className="Training-details-image">
                {!showVideo ? (
                  <>
                    <img
                      src={Training_2}
                      alt="villa Sandalwood Plantation"
                      className="img-fluid"
                      style={{ height: "100%", objectFit: "cover" }}
                    />
                    <button
                      className="Training-play-button"
                      onClick={() => setShowVideo(true)}
                    >
                      ▶
                    </button>
                  </>
                ) : (
                  <div className="video-wrapper" style={{ position: "relative" }}>
                    <video
                      controls
                      autoPlay
                      className="img-fluid"
                      style={{ width: "496px", height: "372px", objectFit: "cover", display: "block", margin: "0 auto" }}
                      onEnded={() => setShowVideo(false)}
                    >
                      {/* <source src={trainingVideo} type="video/mp4" /> */}
                      Your browser does not support the video tag.
                    </video>
                    <button
                      className="close-video-button"
                      onClick={() => setShowVideo(false)}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      ✕
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="Training-additional-content-1">
          <div className="Training-additional-content-layer">
            <h3 className="content-layer-text">Past training program</h3>
            <img
              src={training_img1}
              alt="training_img1"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="Training-additional-content-2">
          <div className="Training-additional-content-layer-2">
            <h3 className="content-layer-text-2">Upcoming training program</h3>
            <p style={{ paddingTop: "20px" }}>To be announced soon...</p>
          </div>
        </div>
      </div>

      <section className="mb-80">
        <Container>
          <div className="farmland-info-bottom" style={{ marginTop: "60px" }}>
            <div className="farmland-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button className="learn-more-button" onClick={handleDownload}>
              Download
            </button>
          </div>
        </Container>
      </section>

      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Training;

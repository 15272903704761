import React, { useState } from "react";
import "./../Sub_Home/Testimonial.css";
import left_button from "../../Assets/left_arrow.png";
import right_button from "../../Assets/right_arrow.png";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  if (images.length === 0) return null; 

  return (
    <div className="slidder-container">
      <div onClick={prevImage} className="slidder-button lefft">
        <img src={left_button} alt="" />
      </div>
      <img
        src={images[currentIndex]}
        alt={`Slide ${currentIndex + 1}`}
        className="slider-images"
      />
      <div onClick={nextImage} className="slidder-button rigght">
      <img src={right_button} alt="" />
      </div>
    </div>
  );
};

export default ImageSlider;

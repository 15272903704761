import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import "./Testimonial.css";
import ImageSlider from "../Layout/ImageSlider";
import VideoSlider from "../Layout/VideoSlider";
import left_button from "../../Assets/left_arrow.png";
import right_button from "../../Assets/right_arrow.png";
import semicolon from "../../Assets/cln.png";
import tes_flower from "../../Assets/tes-flower.png";
import defaultImage from "../../Assets/image_not_found.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Rating } from "@mui/material";
import { compressImages, compressVideos } from "../Layout/Compression";
import CircularProgress from "@mui/material/CircularProgress";

const Testimonial = () => {
  const sliderRef = useRef(null);
  const [testimonialData, setTestimonialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentDescription, setCurrentDescription] = useState(null);
  
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(
          "https://rispl.backendapihub.com/testimonials/view",
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.status === "success") {
          const testimonials = data.data[0].get_data;

          const uniqueTestimonials = Array.from(
            new Map(
              testimonials.map(item => [item.id || item.name, item])
            ).values()
          );

          const processedTestimonials = uniqueTestimonials.map(item => {
            if (item.media) {
              try {
                const mediaPaths = JSON.parse(item.media);
                const mediaFiles = mediaPaths.map(path => ({
                  type: path.endsWith(".mp4") ? "video" : "image",
                  path: `https://rispl.backendapihub.com/images/${path}`,
                }));
                item.compressedMedia = mediaFiles;
              } catch (error) {
                console.error("Failed to parse media:", error);
                item.compressedMedia = [];
              }
            }
            return item;
          });

          setTestimonialData(processedTestimonials.slice(0, 6));
        }
      } catch (error) {
        console.error("Failed to fetch testimonials:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  const next = () => sliderRef.current.slickNext();
  const previous = () => sliderRef.current.slickPrev();

  const settings = {
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openVideoModal = video => {
    setCurrentVideo(video);
    setModalIsOpen(true);
  };

  const openDescriptionModal = description => {
    setCurrentDescription(description);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
    setCurrentDescription(null);
  };

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  return (
    <div className="testimonial">
      <div className="container d-flex justify-content-between flex-wrap align-items-center mb-5">
        <h1 className="tes-titlee">What our clients say about us</h1>
        {testimonialData.length > 3 && (
          <div
            className={`slider-controls ${
              testimonialData.length > 3 ? "multiple-cards" : ""
            } mt-2 mt-sm-0`}
          >
            <img
              src={left_button}
              alt="Previous"
              className="left-arrow"
              onClick={previous}
            />
            <img
              src={right_button}
              alt="Next"
              className="right-arrow"
              onClick={next}
            />
          </div>
        )}
      </div>
      <div className="container">
        <div
          className={`tes-main-container ${
            testimonialData.length <= 3 ? "single-card" : "multiple-cards"
          }`}
        >
          <img
            className="tes-flower"
            src={tes_flower}
            alt="Flower decoration"
          />

          {loading ? (
            <div className="loading-spinner">
              <CircularProgress size={60} />
            </div>
          ) : (
            <>
              {testimonialData.length === 0 ? (
                <div className="no-testimonials-message">
                  <h2>
                    No testimonials available at this time. Please check back
                    later!
                  </h2>
                </div>
              ) : (
                <>
                  <Slider
                    ref={sliderRef}
                    {...settings}
                    className="testimonial-slider"
                  >
                    {testimonialData.map((item, index) => (
                      <div className="item-box" key={index}>
                        <div className="tes-card">
                          <div className="card-header mb-3">
                            <img
                              className="semu"
                              src={semicolon}
                              alt="Semicolon decoration"
                              onError={handleImageError}
                            />
                            {item.compressedMedia &&
                            item.compressedMedia.length > 0 ? (
                              item.compressedMedia.length === 1 ? (
                                item.compressedMedia[0].type === "video" ? (
                                  <video
                                    controls
                                    muted
                                    preload="auto"
                                    className="thumbnail-video"
                                    onClick={() =>
                                      openVideoModal(
                                        item.compressedMedia[0].path
                                      )
                                    }
                                  >
                                    <source
                                      src={item.compressedMedia[0].path}
                                      type="video/mp4"
                                      onError={handleImageError}
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img
                                    src={item.compressedMedia[0].path}
                                    alt="Testimonial"
                                    className="thumbnail-image"
                                    onError={handleImageError}
                                  />
                                )
                              ) : (
                                <>
                                  <ImageSlider
                                    images={item.compressedMedia
                                      .filter(media => media.type === "image")
                                      .map(media => media.path)}
                                      onError={handleImageError}
                                  />
                                  <VideoSlider
                                    videos={item.compressedMedia
                                      .filter(media => media.type === "video")
                                      .map(media => media.path)}
                                      onError={handleImageError}
                                  />
                                </>
                              )
                            ) : (
                              // Case when no media is present - show full description
                              item.description && (
                                <div>
                                  <p className="truncated-description">
                                    {item.description.length > 300
                                      ? `${item.description.substring(
                                          0,
                                          300
                                        )}...`
                                      : item.description}
                                    {item.description.length > 300 && (
                                      <button
                                        className="see-moore-btn"
                                        onClick={() =>
                                          openDescriptionModal(item.description)
                                        }
                                      >
                                        Read more
                                      </button>
                                    )}
                                  </p>
                                </div>
                              )
                            )}

                            {item.compressedMedia &&
                              item.compressedMedia.length > 0 &&
                              item.description && (
                                <p className="truncated-descripption">
                                  {item.description.length > 30
                                    ? `${item.description.substring(0, 30)}...`
                                    : item.description}
                                  {item.description.length > 30 && (
                                    <button
                                      className="see-moore-btn"
                                      onClick={() =>
                                        openDescriptionModal(item.description)
                                      }
                                    >
                                      Read more
                                    </button>
                                  )}
                                </p>
                              )}
                          </div>
                          <hr className="hr-footer" />
                          <div className="tes-footer">
                            <h1 className="tes-client">{item.name}</h1>
                            <Rating
                              name="client-rating"
                              value={item.rating}
                              readOnly
                              precision={0.5}
                              className="star-rating"
                            />
                            <p className="tes-designation">
                              {item.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="video-modal"
        overlayClassName="video-overlay"
      >
        <FontAwesomeIcon
          icon={faWindowClose}
          className="close-button"
          onClick={closeModal}
        />
        {currentVideo && (
          <video autoPlay controls className="video-element">
            <source src={currentVideo} type="video/mp4" />
          </video>
        )}
        {currentDescription && (
          <div className="description-modal">
            <h2>Client say about us</h2>
            <p>{currentDescription}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Testimonial;

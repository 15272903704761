import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layout from "../../Components/Layout/Layout";
import toast from "react-hot-toast";
import UserMenu from "../../Components/Layout/UserMenu";
import { useNavigate, useLocation } from "react-router-dom";
import "./css/Dashboard.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import usericon from "../../Assets/dashUseIcon.png";
import mailicon from "../../Assets/dashMailIcon.png";
import phoneicon from "../../Assets/dashPhoneIcon.png";
import add1icon from "../../Assets/dashAddressoIcon.png";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Footer from "../../Components/Layout/Footer";
import AddAddressPopup from "../AddAddressPopup";

const Dashboard = () => {
  const location = useLocation();
  const [cartItems, setCartItems] = useState([]);
  const [profile, setProfile] = useState(null);
  const [addressData, setAddressData] = useState([]);
  const [addressLoading, setAddressLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", mobile: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [formErrors, setFormErrors] = useState({ email: "", mobile: "" });
  const nameInputRef = useRef(null);

  const accessToken = sessionStorage.getItem("auth");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(
          "https://rispl.backendapihub.com/profile/view",
          {
            method: "GET",
            headers: {
              accept: "application/json",
              "access-token": accessToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(data.message || "Network response was not ok");
        }

        const data = await response.json();
        if (data.status === "Success") {
          setProfile(data.data[0]);
          setFormData({
            name: data.data[0].name,
            email: data.data[0].email,
            mobile: data.data[0].mobile,
          });
        } else {
          throw new Error(data.message || "Failed to fetch profile.");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          "https://rispl.backendapihub.com/address/getall",
          {
            headers: {
              "access-token": accessToken,
            },
          }
        );

        if (response.data.status === "success") {
          setAddressData(response.data.data);
        } else {
          throw new Error(
            response.data.message || "Failed to fetch addresses."
          );
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchProfile();
    fetchAddresses();
  }, [accessToken]);

  useEffect(() => {
    if (isEditing && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [isEditing]);

  const validateEmail = email => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = mobile => {
    return /^[0-9]{10}$/.test(mobile);
  };

  const handleAddAddress = newAddress => {
    setAddressData(prevAddresses => [...prevAddresses, newAddress]);
    toast.success("Address added successfully!");
    setIsPopupOpen(false);
    window.location.reload();
  };

  const handleDeleteAddress = (addressId, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this address?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.delete(
                `https://rispl.backendapihub.com/address/delete/${addressId}`,
                {
                  headers: {
                    accept: "application/json",
                    "access-token": accessToken,
                  },
                }
              );

              if (
                response.status === 200 &&
                response.data.status === "success"
              ) {
                const updatedAddresses = addressData.filter(
                  (_, i) => i !== index
                );
                setAddressData(updatedAddresses);
                if (updatedAddresses.length === 0) {
                  // Set a state variable to indicate no addresses
                  setAddressLoading(true);
                  setTimeout(() => {
                    setAddressLoading(false);
                  }, 1000);
                }
                toast.success("Address deleted successfully.");
              } else {
                toast.error("Failed to delete address.");
              }
            } catch (error) {
              toast.error("Error deleting address.");
            }
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;

    // Update the form data
    setFormData(prevData => ({ ...prevData, [name]: value }));

    // Validate input fields
    if (name === "email") {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        email: validateEmail(value) ? "" : "Invalid email format",
      }));
    } else if (name === "mobile") {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        mobile: validatePhoneNumber(value)
          ? ""
          : "Phone number must be exactly 10 digits",
      }));
    }
  };

  const handleUpdateProfile = async e => {
    e.preventDefault();

    // Check for errors before submitting
    if (formErrors.email || formErrors.mobile) {
      toast.error("Please correct the errors in the form.");
      return;
    }

    try {
      const response = await axios.post(
        "https://rispl.backendapihub.com/profile/update",
        {
          name: formData.name,
          mobile: formData.mobile,
          email: formData.email,
        },
        {
          headers: {
            "access-token": accessToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (
        response.data.status &&
        response.data.status.toLowerCase() === "success"
      ) {
        setProfile(prevProfile => ({
          ...prevProfile,
          name: formData.name,
          mobile: formData.mobile,
          email: formData.email,
        }));
        toast.success("Profile updated successfully!");
        setIsEditing(false);
      } else {
        toast.error("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="dash-top-section"></div>
      <div className="dash-container">
        <div className="d-flex flex-lg-nowrap userActflexx ">
          <UserMenu />

          <div className="drc d-containt">
            <h1 className="text-start-pro p-0">Profile</h1>
            <hr className="dhr dhrn" />

            {loading && <div className="loading-message">Loading...</div>}
            {error && <div className="error-message">{error}</div>}

            {!loading && !error && profile && (
              <div className="details-container">
                <IconButton
                  className="update-icon-button"
                  style={{
                    float: "right",
                    color: "#EDBD67",
                  }}
                  onClick={handleEditClick}
                >
                  <EditIcon />
                </IconButton>
                <form onSubmit={handleUpdateProfile} className="profile-form">
                  <div className="d-lg-flex userMailspace">
                    <div className="detail w-50">
                      <img src={usericon} alt="" className="icon" />
                      <div className="info">
                        <span className="dashTitle">Name </span>
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className={`value ${isEditing ? "editing" : ""}`}
                          ref={nameInputRef}
                          disabled={!isEditing}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                    </div>
                    <div className="detail">
                      <img src={mailicon} alt="" className="icon" />
                      <div className="info">
                        <span className="dashTitle">Email Address </span>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className={`value ${isEditing ? "editing" : ""}`}
                          disabled={!isEditing}
                        />
                        {formErrors.email && (
                          <span className="error-message">
                            {formErrors.email}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="detail">
                    <img src={phoneicon} alt="" className="icon" />
                    <div className="info">
                      <span className="dashTitle">Phone </span>
                      <input
                        type="text"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className={`value ${isEditing ? "editing" : ""}`}
                        disabled={!isEditing}
                      />
                      {formErrors.mobile && (
                        <span className="error-message">
                          {formErrors.mobile}
                        </span>
                      )}
                    </div>
                  </div>
                  {isEditing && (
                    <button type="submit" className="submit-button-update">
                      Update
                    </button>
                  )}
                  <hr className="dhr" />
                </form>
                <div className="address-container">
                  <div className="address-header">
                    <h2 className="address-title">Addresses</h2>
                    <button
                      className="add-address-buttonn"
                      onClick={() => setIsPopupOpen(true)}
                    >
                      + Add New Address
                    </button>
                  </div>
                  {addressData.length > 0 ? (
                    addressData.map((addr, index) => (
                      <div key={index} className="detail">
                        <img src={add1icon} alt="" className="icon" />
                        <div className="info">
                          <span className="dashTitle">
                            {addr.type || `Address ${index + 1}`}
                          </span>
                          <span className="value">
                            {addr.address1}, {addr.address2} {addr.city},{" "}
                            {addr.state} - {addr.pincode}
                          </span>
                        </div>
                        <DeleteIcon
                          className="delete-icon"
                          onClick={() => handleDeleteAddress(addr.id, index)}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="no-address-message">
                      No shipping addresses available.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddAddressPopup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onAddAddress={handleAddAddress}
      />
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Dashboard;

import React, { useState } from "react";
import "../../Styles/Farmland.css";
import servicespage1 from "../.././Assets/servicespage1.jpg";
import leaf from "../.././Assets/gift-vector-leaf.png";
import gift1 from "../.././Assets/gift-faq-one.png";
import gift2 from "../.././Assets/gift-faq-two.png";
import Farmlandd from "../.././Assets/farmland_img_1.jpg";
import Villa from "../.././Assets/farmland_img_2.jpg";
import Chandan from "../.././Assets/farmland_img_3.jpeg";
import bookFlipPdf from "../.././Assets/Services-booklet.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Layout from "../.././Components/Layout/Layout";
import Footer from "../.././Components/Layout/Footer";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

const Farmland = () => {
  const [cartItems, setCartItems] = useState([]);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf;
    link.download = "Services-booklet.pdf";
    link.click();
  };

  const toggleFaq = index => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "1. What is the best climate for sandalwood cultivation?",
      answer:
        "Sandalwood thrives in tropical and subtropical climates with well-drained soils, ample sunlight, and moderate rainfall.",
    },
    {
      question: "2. How long does it take for sandalwood trees to mature?",
      answer:
        "Sandalwood trees typically take around 15 to 20 years to reach maturity and produce quality heartwood. However, the genetics of our plants ensure they reach maturity in approximately 12 to 15 years.",
    },
    {
      question: "3. What type of soil is suitable for sandalwood planting?",
      answer:
        "Well-drained laterite, red soil, sandy or loamy soils with a pH of 6.0 to 7.5 are ideal for sandalwood cultivation.",
    },
    {
      question: "4. Can sandalwood be grown in mixed plantations?",
      answer:
        "Yes, sandalwood can be successfully grown alongside other compatible tree species, which can enhance soil health and support biodiversity.",
    },
    {
      question: "5. How should sandalwood trees be watered?",
      answer:
        "Newly planted trees require regular watering until established, after which they can tolerate periods of drought. Watering through drip irrigation is the best method. It’s essential to avoid waterlogging.",
    },
    {
      question: "6. Is sandalwood plantation a profitable venture?",
      answer:
        "Yes, sandalwood can be a lucrative investment due to its high market value, especially for heartwood and essential oils.",
    },
    {
      question:
        "7. Are there any legal regulations regarding sandalwood cultivation?",
      answer:
        "Sandalwood plantation is absolutely legal and does not require any government permission to plant. t is essential to update your land records to reflect any plantation activities conducted by individuals.",
    },
  ];


  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="farmland-container">
        <div className="container">
          <div className="farmland-text">
            {/* <span className="text1">Farmland</span>{" "} */}
            <span className="text2">Sandalwood Plantation</span>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="farmland-info-section">
            <img src={leaf} alt="leaf" className="leaf" />
            <Row>
              <div className="col-lg-7 col-sm-12 col-12 p-2">
                <div className="farmland-info-content">
                  <h2>Sandalwood Plantation</h2>
                  <p>
                    An opportunity for your farmland or agricultural land that
                    might currently be underutilized. With the assistance of
                    Royal Indian Sandalwood Private Limited, this land has the
                    potential to be transformed into a valuable asset through
                    sandalwood cultivation. Royal Indian Sandalwood Private
                    Limited specializes in sustainable sandalwood cultivation
                    and provides a range of financial models tailored to suit
                    your specific needs. These models not only guarantee a
                    consistent income stream but also support environmental
                    conservation and sustainable development. You can leverage
                    our expertise in managing sandalwood plantations, which are
                    renowned for their high market value. This initiative not
                    only increases the value of your land but also positively
                    impacts the local economy and biodiversity.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-sm-12 col-12 p-2">
                <img
                  src={servicespage1}
                  alt="Right Image"
                  className="img-fluid"
                  style={{ height: "100%", objectFit: "cover" }}
                />
              </div>
            </Row>
          </div>
        </div>
      </section>
      <section className="services_container">
        <Container>
          <div className="services-Plantation-containerr">
            <div className="services-Plantation-contentt">
              {/* <img className="images-Plantationn" src={servicespage1} alt="" /> */}
              <p className="services-description-Plantationn">
                Sandalwood Plantation Models
              </p>
            </div>
          </div>
        </Container>
      </section>
      <div className="farmland-sectioon container">
        <div className="farmlandd-container">
          <img className="farmlandd-image" src={Farmlandd} alt="Farmland" />
          <div className="farmlandd-text-container">
            <h2 className="farmlandd-title">Farmland Sandalwood Plantation</h2>
            <p className="farmlandd-description">
              An opportunity for your farmland or agricultural land that might
              currently be underutilized. With the assistance of Royal Indian
              Sandalwood Private Limited, this land has the potential to be
              transformed into a valuable asset through sandalwood cultivation.
            </p>
            <button className="know-more-button" onClick={() => handleNavigate("/FarmlandPlantation")}>
              Know More
            </button>
          </div>
        </div>
        <div className="farmlandd-container farmland2">
          <img className="farmlandd-image" src={Villa} alt="Villa" />
          <div className="farmlandd-text-container">
            <h2 className="farmlandd-title">
              Villa/Bunglow sandalwood plantation
            </h2>
            <p className="farmlandd-description">
              Our latest initiative involves planting sandalwood trees around
              individual bungalows, redefining luxury living while contributing
              to a greener, more sustainable future.
            </p>
            <button className="know-more-button" onClick={() => handleNavigate("/Villa_Bunglow")}>
              Know More
            </button>
          </div>
        </div>
        <div className="farmlandd-container">
          <img className="farmlandd-image" src={Chandan} alt="Villa" />
          <div className="farmlandd-text-container">
            <h2 className="farmlandd-title">Har Ghar Chandan</h2>
            <p className="farmlandd-description">
              Many people are unaware and find it hard to believe that
              sandalwood can be successfully cultivated in a pot at home.
              <br />
              Sandalwood, a cherished part of our heritage, is believed to bring
              good luck, positive energy and wealth to our homes. Considered as
              one of the holiest plants, the Brahma Vaivarta Purana mentions
              Goddess Lakshmi residing within the sandalwood tree. Consequently,
              we include sandalwood in various forms during auspicious
              ceremonies and rituals. With minimal maintenance and watering,
              sandalwood can thrive in our homes. Our aspiration is to see
              sandalwood plants growing in every household across India.
            </p>
            <button className="know-more-button" onClick={() => handleNavigate("/gifting")}            >
              Know More
            </button>
          </div>
        </div>
      </div>
      <section className="mb-80">
        <Container>
          <div className="farmland-info-bottom">
            <div className="farmland-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button
              className="learn-more-button"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
        </Container>
      </section>
      <div className="farmland-faq-section">
        <img src={gift1} alt="faq-leaf" className="gift1" />
        <img src={gift2} alt="faq-leaf" className="gift2" />
        <h3 className="farmland-faq-title">Frequently Asked Questions</h3>
        <Container>
          <div className="farmland-faq-content">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`faq-item ${index === faqs.length - 1 ? "last" : ""
                  }`}
              >
                <div
                  className="faq-question"
                  onClick={() => toggleFaq(index)}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                >
                  <p className="faq-toggle">{faq.question}</p>
                  <FontAwesomeIcon
                    icon={openFaqIndex === index ? faChevronUp : faChevronDown}
                    className="faq-icon"
                  />
                </div>
                <span
                  className={`faq-answer ${openFaqIndex === index ? "open" : ""
                    }`}
                  style={{ fontSize: "16px" }}
                >
                  {faq.answer}
                </span>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Farmland;

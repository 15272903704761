import React from "react";
import "./ServicesBooklet.css";
import bookFlipPdf from "../../Assets/Services-booklet.pdf";



const ServicesBooklet = () => {

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf; 
    link.download = "Services-booklet.pdf";
    link.click();
  };

  return (
    <section>
      {/* <div className="container" style={{ padding: 0 }}> */}
        <div className="ServicesBooklet-info-bottom">
          <div className="ServicesBooklet-info-bottom-left">
            <span>HAVE A LOOK</span>
            <h3>Services booklet</h3>
          </div>
          <button
            className="ServicesBooklet-learn-more-button"
            onClick={handleDownload}
          >
            Download
          </button>
        </div>
      {/* </div> */}
    </section>
  );
};

export default ServicesBooklet;
import React, { useState } from "react";
import "./../Sub_Home/Testimonial.css";
import left_button from "../../Assets/left_arrow.png";
import right_button from "../../Assets/right_arrow.png";

const VideoSlider = ({ videos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextVideo = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevVideo = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  if (videos.length === 0) return null; 

  return (
    <div className="slidder-container">
      <div onClick={prevVideo} className="slidder-button lefft">
        <img src={left_button} alt="Previous" />
      </div>
      <video
        src={videos[currentIndex]}
        controls
        className="slider-images"
      />
      <div onClick={nextVideo} className="slidder-button rigght">
        <img src={right_button} alt="Next" />
      </div>
    </div>
  );
};

export default VideoSlider;

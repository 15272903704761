import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Gifting.css";
import ben1 from "../Assets/benefit-icon1.png";
import ben2 from "../Assets/benefit-icon2.png";
import ben3 from "../Assets/benefit-icon3.png";
import ben4 from "../Assets/benefit-icon4.png";
import ben5 from "../Assets/benefit-icon5.png";
import ben6 from "../Assets/benefit-icon6.png";
import hrline from "../Assets/hr_line.png";
import vrline from "../Assets/vr_line.png";
import leaf_vtr from "../Assets/gift-vector-leaf.png";
import flower1 from "../Assets/gift-faq-one.png";
import flower2 from "../Assets/gift-faq-two.png";
import leafvtr2 from "../Assets/gift-vector-leaf2.png";
import Footer from "../Components/Layout/Footer";
import Layout from "../Components/Layout/Layout";
import { Container } from "react-bootstrap";

const Gifting = () => {
  const [cartItems, setCartItems] = useState([]);
  const [harGharText, setHarGharText] = useState("");
  const [chandanText, setChandanText] = useState("");
  const [languageIndex, setLanguageIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const navigate = useNavigate();
  const toRotate = [
    ["har ghar chandan"],
    ["हर घर चंदन"],
    ["હર ઘર ચંદન"],
    ["ஹர் கர் சந்தன்"],
  ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, typingSpeed);

    return () => {
      clearInterval(ticker);
    };
  }, [harGharText, chandanText, typingSpeed]);

  const tick = () => {
    let languageLength = toRotate[languageIndex].length;
    let fullHarGharText = toRotate[languageIndex][0] + " ";
    let fullChandanText = toRotate[languageIndex][1];
    let updatedHarGharText = isDeleting
      ? fullHarGharText.substring(0, harGharText.length - 1)
      : fullHarGharText.substring(0, harGharText.length + 1);

    setHarGharText(updatedHarGharText);

    if (!isDeleting && updatedHarGharText === fullHarGharText) {
      setIsDeleting(true);
      setTypingSpeed(period);
    } else if (isDeleting && updatedHarGharText === "") {
      setIsDeleting(false);
      setLanguageIndex((languageIndex + 1) % toRotate.length);
      setTypingSpeed(150);
    } else {
      setTypingSpeed(150);
    }
  };

  const goToChandanPlant = () => {
    navigate(`/product/${64}`);
  };
  return (
    <>
      {/* <Navbar showNormalNav={false} /> */}
      <Layout
        setCartItems={setCartItems}
        cartItems={cartItems}
        isNavbar={true}
        textColor="white"
      />
      <div className="position-relative">
        <div className="Containeranime position-relative">
          <h6 className="gifting-banner-title">
            {harGharText} {chandanText}
          </h6>
        </div>

        {/* Benefits section */}
        <div className="container">
          <div className="benefits position-relative">
            <img className="leaf-vtr img-fluid" src={leaf_vtr} alt="" />
            <div className="inner-benefits">
              <h1>Why Har Ghar Chandan?</h1>

              <div className="benefits-content row align-items-stretch">
                {/* 1st */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 border_bottom_right">
                  <div className="outer-content">
                    <div className="img">
                      <img src={ben1} alt="" />
                    </div>

                    <div className="content pt-4">
                      <h2>Personal Pride</h2>
                      <p>
                        It is a matter of personal pride to own and
                        <br />
                        grow a sandalwood plant at home
                      </p>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 border_bottom_right">
                  <div className="outer-content">
                    <div className="img">
                      <img src={ben2} alt="" />
                    </div>

                    <div className="content pt-4">
                      <h2>Good Luck And Wealth</h2>
                      <p>It's a plant of good luck and wealth</p>
                    </div>
                  </div>
                </div>
                {/* 3rd */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 border_bottom">
                  <div className="outer-content">
                    <div className="img">
                      <img src={ben3} alt="" />
                    </div>

                    <div className="content pt-4">
                      <h2>Asset</h2>
                      <p>It's an asset for yourself</p>
                    </div>
                  </div>
                </div>
                {/* 4th */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 border_right">
                  <div className="outer-content">
                    <div className="img">
                      <img src={ben4} alt="" />
                    </div>

                    <div className="content pt-4">
                      <h2>Positive Energy</h2>
                      <p>
                        It creates an aura and spreads a lot of
                        <br /> positive energy.
                      </p>
                    </div>
                  </div>
                </div>
                {/* 5th */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 border_right">
                  <div className="outer-content">
                    <div className="img">
                      <img src={ben5} alt="" />
                    </div>

                    <div className="content pt-4">
                      <h2>Low Maintenance</h2>
                      <p>It's a low maintenance less water plant.</p>
                    </div>
                  </div>
                </div>
                {/* 6th */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="outer-content">
                    <div className="img">
                      <img src={ben6} alt="" />
                    </div>

                    <div className="content pt-4">
                      <h2>Rebuilding Our Country</h2>
                      <p>
                        It's a small contribution by every individual <br />
                        in rebuilding our countries lost legacy
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="gift-leaf-vtr2" src={leafvtr2} alt="" />
          </div>
        </div>
      </div>

      <div className="gftmain ">
        <div className="Experience-section text-center">
          <h1>Experience The Product</h1>

          {/* Circular Video Section */}
          <div className="container">
            <video className="circle-video" controls>
              {/* <source src={video} type="video/mp4" /> */}
              {/* Your browser does not support the video tag. */}
            </video>
          </div>

          <div className="text-center d-flex justify-content-center align-items-center">
            <button className="buy-now-button" onClick={goToChandanPlant}>
              BUY NOW
            </button>
          </div>
        </div>
      </div>
      {/* FAQ */}

      <div className="FooterContainer">
        <div className="accordian-background">
          <h1 className="text-center">Frequently Asked Questions</h1>
          <div className="container Accordian position-relative">
            <div className="accordion faq-main" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <div
                    className="accordion-button d-flex align-items-center justify-content-between"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    role="button"
                  >
                    <span>1. Is it legal to grow Sandalwood?</span>
                  </div>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Yes, it is absolutely legal and does not require any
                      government permission to grow sandalwood.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <div
                    className="accordion-button collapsed d-flex align-items-center justify-content-between"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    role="button"
                  >
                    <span>2. Does Sandalwood grow in a pot at home?</span>
                  </div>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Yes. Many people are not aware of this fact that it does
                      grow beautifully.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <div
                    className="accordion-button collapsed d-flex align-items-center justify-content-between"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    role="button"
                  >
                    <span>3. What does it need to grow in a pot?</span>
                  </div>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      It needs sunlight, minimal watering, care and our company
                      formulated specific sandalwood fertiliser.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <div
                    className="accordion-button collapsed d-flex align-items-center justify-content-between"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                    role="button"
                  >
                    <span>
                      4. Why should you keep sandalwood plant at home?
                    </span>
                  </div>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      It's a plant of good luck and wealth because Goddess Laxmi
                      resides in the plant of Sandalwood. It spreads positive
                      energy and is a evergreen plant. Its very rare to get
                      sandalwood plant.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <div
                    className="accordion-button collapsed d-flex align-items-center justify-content-between"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                    role="button"
                  >
                    <span>5. Does it require lot of maintenance?</span>
                  </div>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      It’s a plant that requires minimal water and maintenance.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <div
                    className="accordion-button collapsed d-flex align-items-center justify-content-between"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                    role="button"
                  >
                    <span>6. Does Sandalwood require a host plant?</span>
                  </div>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Yes, it’s a semi parasitic plant and requires a host
                      plant. Our company provides the host plant along with the
                      sandalwood plant. Additionally, the company also provides
                      the host plant seeds with the sandalwood plant package.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />
            </div>
            <img className="faq-flower-1" src={flower1} alt="flower1" />
            <img className="faq-flower-2" src={flower2} alt="flower2" />
          </div>
        </div>
      </div>

      {/* <Footer/> */}
      <Footer showBothDivs={false} />
    </>
  );
};

export default Gifting;

import React, { useState } from "react";
import "../../Styles/Villa.css";
import villa1 from "../.././Assets/villa1.png";
import villa2 from "../.././Assets/villa2.png";
import gift1 from "../.././Assets/gift-faq-one.png";
import gift2 from "../.././Assets/gift-faq-two.png";
import bookFlipPdf from "../.././Assets/Services-booklet.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Layout from "../.././Components/Layout/Layout";
import Footer from "../.././Components/Layout/Footer";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";


const Villa = () => {
  const [cartItems, setCartItems] = useState([]);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf; 
    link.download = "Services-booklet.pdf";
    link.click();
  };

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "1. Can you tell us the forte of your company?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "2. Where is your company located?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question:
        "3. Which all part of the country does your company provide its services?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "4. Which sandalwood does your company deal with?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "5. What are the different names of sandalwood?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "6. Is red sandalwood and yellow sandalwood same?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question:
        "7. What are the main differences between red and yellow sandalwood?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
  ];

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="villa-section">
        <div className="container">
          <div className="text-overlay">
            <span className="textt-1">Villa/Bungalow</span>
            <span className="textt-2">Sandalwood Plantation</span>
          </div>
        </div>
      </div>
      <section>
        <div className="Villa-link-section">
          <span
            className="Villa-link"
            onClick={() => handleNavigate("/SandalwoodPlantation")}
          >
            Sandalwood Plantation
          </span>
          <span className="separator">•</span>{" "}
          <span
            className="Villa-link"
            onClick={() => handleNavigate("/FarmlandPlantation")}
          >
            Farmland
          </span>
          <span className="separator">•</span> 
          <span
            className="Villa-link"
            onClick={() => handleNavigate("/gifting")}
            // style={{width: "100%"}}
          >
            Har Ghar Chandan
          </span>
        </div>
        <div className="container">
          <div className="details-content">
            <Row>
              <div className="col-lg-7 col-sm-12 col-12 p-2">
                <div className="details-text">
                  <h2>Villa/Bungalow Sandalwood Plantation</h2>
                  <p>
                    At “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED”, we believe in
                    creating spaces that blend luxury with sustainability, where every
                    element serves a purpose beyond aesthetics. Our latest initiative
                    involves planting sandalwood trees around individual bungalows,
                    redefining luxury living while contributing to a greener, more
                    sustainable future. Our vision is to create harmonious
                    environments where residents can experience the timeless elegance
                    of sandalwood while contributing to environmental conservation.
                    Through meticulous planning and sustainable practices, we aim to
                    establish thriving sandalwood plantations that enrich lives and
                    inspire stewardship of our natural resources. Our commitment to
                    sustainability extends to sites that promote biodiversity and
                    environmental balance, creating havens of tranquility and natural
                    beauty.
                  </p>
                </div>
              </div>
              {/* <div className="details-image"> */}
              <div className="col-lg-5 col-sm-12 col-12 p-2">
                <img src={villa1} alt="villa Sandalwood Plantation"
                  className="img-fluid"
                  style={{ height: "100%", objectFit: "cover" }} />
              </div>
            </Row>
          </div>
          <div className="container">
            <div className="additional-content">
            <Row>
              <div className="additional-content-layer">
                <h3>Design of the Landscape</h3>
                <p>
                  The design of the landscape surrounding the bungalows is
                  meticulously crafted to integrate sandalwood trees seamlessly.
                  Walkways adorned with fragrant flora lead to serene corners where
                  residents can connect with nature. Every aspect of the layout is
                  curated to enhance well-being and foster a sense of connection
                  with the environment. We employ cutting-edge techniques in
                  sandalwood cultivation, leveraging expertise and innovation to
                  ensure the health and vitality of each tree. Our dedicated team of
                  professionals oversees every stage of growth, from planting to
                  sustainable harvesting, adhering to best practices and ethical
                  standards. The plantation of sandalwood trees offers dual benefits
                  - economic and environmental. Residents not only enjoy the luxury
                  of living amidst these revered trees but also contribute to a
                  sustainable economy through potential wood and oil production.
                  Moreover, the trees play a vital role in carbon sequestration,
                  soil conservation, and biodiversity enhancement, aligning with our
                  commitment to environmental stewardship. Planting sandalwood
                  around your bungalows is a testament to our ethos of responsible
                  luxury and environmental consciousness. We invite you to be part
                  of this journey, where luxury meets sustainability, and where
                  every tree planted is a step towards a greener, more harmonious
                  world.
                </p>
                <img src={villa2} alt="villa" className=" img-fluid"  style={{ height: "100%", objectFit: "cover", position: "relative", top: "20px" }} />
              </div>
            </Row>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-80">
        <Container>
          <div className="farmland-info-bottom" style={{ top: "-85px" }}>
            <div className="farmland-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button
              className="learn-more-button"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
        </Container>
      </section>
      <section className="economies-section">
      <Container fluid className="economies-container">
        <Row className="align-items-center economies-content">
          <Col xs={12} md={8} className="economies-left">
            <h2>Economies of Villa / Bungalow Sandalwood Plantation</h2>
          </Col>
          {/* <Col xs={12} md={4} className="economies-right text-md-end text-center">
            <div className="experience-button">Experience Our Service</div>
          </Col> */}
        </Row>
        <Row className="economies-details">
          <Col xs={12} md={4} className="economies-detail">
            <h3>Best Sandalwood <br /> Saplings</h3>
            <p className="p1">
              We meticulously choose <span>best sandalwood saplings</span> from our nursery
              and plant them strategically around your bungalow using scientific methods and skilled
              laborers trained to professional standards.
            </p>
          </Col>
          <Col xs={12} md={4} className="economies-detail">
            <h3>We offer service at a modest price</h3>
            <p className="p2">
              We offer our services at a modest price of <span>Rs. 15,000 to Rs. 25,000/- inclusive of GST,</span>
              {" "}covering both the plantation process and ongoing support. The above price also covers
              the plantation of host plants essential for promoting the healthy growth of sandalwood.
            </p>
          </Col>
          <Col xs={12} md={4} className="economies-detail">
            <h3>Appreciating in value over time</h3>
            <p className="p3">
              Sandalwood stands out by appreciating in value over time, unlike everything else which
              typically depreciates, <span>making it a potential multibagger</span>.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
      <div className="farmland-faq-section">
        <img src={gift1} alt="faq-leaf" className="gift1" />
        <img src={gift2} alt="faq-leaf" className="gift2" />
        <h3 className="farmland-faq-title">Frequently Asked Questions</h3>
        <Container>
          <div className="farmland-faq-content">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`faq-item ${
                  index === faqs.length - 1 ? "last" : ""
                }`}
              >
                <div
                  className="faq-question"
                  onClick={() => toggleFaq(index)}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                >
                  <p className="faq-toggle">{faq.question}</p>
                  <FontAwesomeIcon
                    icon={openFaqIndex === index ? faChevronUp : faChevronDown}
                    className="faq-icon"
                  />
                </div>
                <span
                  className={`faq-answer ${
                    openFaqIndex === index ? "open" : ""
                  }`}
                  style={{ fontSize: "16px" }}
                >
                  {faq.answer}
                </span>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Villa;

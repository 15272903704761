import React from "react";
// import { useNavigate } from "react-router-dom";
import "./TissueBanner.css";
import cruelimg_mob from "../../Assets/cruelimg_mob.png";
import art1 from "../../Assets/banner-art1.png";
import art2 from "../../Assets/banner-art2.png";
import img1 from "../../Assets/cruelty.png";
import img2 from "../../Assets/organic.png";
import img3 from "../../Assets/MI.png";
import img4 from "../../Assets/quality.jpg";
import img5 from "../../Assets/np.png";
import { Container } from "react-bootstrap";

const TissueBanner = () => {
  // const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   window.scrollTo(0, 0);
  //   navigate("/products");
  // };
  return (
    <>
      <div className="banner-main">
        <Container>
          <div className="banner-content">
            <h1>Tissue Cultured Plant</h1>
            <p>
              We offer genetically superior tissue-cultured sandalwood plants.
            </p>
            <button className="banner-btn" disabled>
            Coming Soon
            </button>
          </div>
        </Container>
      </div>
      <div className="p-relative" style={{ position: "relative" }}>
        <div className="banner-second">
          <Container>
            <div className="row">
              <div className="col-lg-3 col-12 col-sm-12 col-md-4">
                <h1>Clean, Cruelty Free Beauty</h1>
              </div>
              <div className="col-12 col-md-8 col-sm-12 col-lg-9 text-center">
                <p>
                At Sandaura, we take pride in offering premium sandalwood products that uphold the highest standards of quality and purity. Our commitment to excellence is rooted in ethical sourcing and sustainable practices, ensuring that every product is crafted with care and respect for the environment. By adhering to strict quality control measures and maintaining transparency in our processes, we deliver an authentic, luxurious experience that reflects our dedication to both craftsmanship and ethical integrity.
                </p>
              </div>
            </div>
          </Container>
          <img className="absulate-art" id="art1" src={art1} alt="art" />
          <img className="absulate-art" id="art2" src={art2} alt="art" />
        </div>
        <div className="img_section">
          <Container>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-10">
                <div className="banner-third">
                  <img className="bansticklg" src={img1} alt="cruelty" />
                  <img className="bansticklg" src={img2} alt="organic" />
                  <img className="bansticklg" src={img3} alt="MI" />
                  <img className="bansticklg" src={img4} alt="quality" />
                  <img className="bansticklg" src={img5} alt="np" />
                  <img className="bansticksm" src={cruelimg_mob} alt="mobile" />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default TissueBanner;

import React, { useState, useEffect } from "react";
import "./Hero.css";
// import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import hero1 from "../../Assets/hero-img1c.png";
import hero2 from "../../Assets/hand-plant.jpg";
import hero3 from "../../Assets/carousel-img4.jpg";
import hero4 from "../../Assets/hero-img4.jpg";
// import bubble_1 from "../../Assets/bubble1.png";
// import bubble_3 from "../../Assets/bubble3.png";
// import perlimg from "../../Assets/pearl.png";
// import d_leaf from "../../Assets/d_leaf.png";
// import r_leaf from "../../Assets/r_leaf.png";
import { Container } from "react-bootstrap";

const Hero = () => {
  // const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleButtonClick1 = () => {
  //   window.scrollTo(0, 0);
  //   navigate("/products");
  // };

  // const handleButtonClick2 = () => {
  //   window.scrollTo(0, 0);
  //   navigate("/gifting");
  // };

  // const handleButtonClick3 = () => {
  //   window.scrollTo(0, 0);
  //   navigate("/Services");
  // };

  // const handleButtonClick4 = () => {
  //   window.scrollTo(0, 0);
  //   navigate("products");
  // };
  return (
    <div className="hero">
      <Carousel
        showArrows={true}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        swipeable={!isMobile}
        emulateTouch={!isMobile}
      >
        {/* Slide1 */}
        <div className=" heroslideone position-relative padding-one">
          <Container fluid className="ps-2 ps-md-5">
            <div className="row align-items-center">
              {/* Image Column */}
              <div className="col-md-6 order-1 order-md-2 text-center">
                <img
                  src={hero2}
                  alt=""
                  className="img-fluid  mt-4 mt-sm-3 mt-md-0 hero-image"
                />
              </div>

              {/* Text Column */}
              <div className="col-md-6 text-center order-2 order-md-1">
                <span>
                  {/* <img className="perlimg d-md" src={perlimg} alt="" /> */}
                </span>
                <span>
                  {/* <img className="d_leaf d-md" src={d_leaf} alt="" /> */}
                </span>
                <span>
                  {/* <img className="r_leaf d-md" src={r_leaf} alt="" /> */}
                </span>
                <div className="d-flex flex-column gap-3 text-center flex-wrap hero1-inner-left">
                  <h1 className="h1-typo headerr">
                    Embark On Your Journey Into The World Of Sandalwood With Our
                    Services
                  </h1>
                  <p className="p-typo sbhead">
                    Sandalwood – Plantation, Maintenance, Heartwood Assessment,
                    Training, Consultancy, CSR.
                  </p>
                  {/* <div className="text-lg-start text-md-center">
                    <a onClick={handleButtonClick3} className="hero-btn">
                      EXPLORE MORE
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* slide2 */}
        <div className="heroslidefour position-relative padding-tow">
          <Container fluid className="ps-2 ps-md-5">
            <div className="row align-items-center">
              <div className="col-md-6 order-1 order-md-2">
                <img
                  src={hero4}
                  alt=""
                  className="img-fluid mt-4 mt-sm-3 mt-md-0 hero-image"
                />
              </div>

              {/* <div className="row hero4  order-2 align-items-center"> */}
              <div className="col-md-6 text-center order-2 order-md-1">
                <div className="d-flex flex-column gap-3 text-center flex-wrap hero1-inner-left">
                  <h1 className="h2-typo">Har Ghar Chandan</h1>
                  <p className="p-typo sbhead">
                    Bringing Chandan Home: Reviving India's Rich Legacy, One{" "}
                    {/* <br /> */}
                    Household at a Time.
                  </p>
                  {/* <div className="text-lg-start text-md-center">
                    <a onClick={handleButtonClick2} className="hero-btn">
                      EXPLORE MORE
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* slide3 */}
        <div className="heroslidethree position-relative padding-three">
          <Container fluid className="ps-2 ps-md-5">
            <div className="row align-items-center">
              <div className="col-md-6 order-1 order-md-2">
                <img
                  src={hero3}
                  alt=""
                  className="img-fluid mt-4 mt-sm-3 mt-md-0 hero-image"
                />
              </div>

              <div className="col-md-6 text-center order-2 order-md-1">
                <div className="d-flex flex-column gap-3 text-center flex-wrap hero1-inner-left">
                  <h1 className="h3-typo">Sandalwood Pioneers</h1>
                  <p className="p-typo sbhead">
                    Reviving Indian Legacy: Pioneering Sandalwood Excellence,
                    Cultivating <br /> India's Pride, and Leading the Way to a
                    Fragrant Future
                  </p>
                  {/* <div className="text-lg-start text-md-center">
                    <a onClick={handleButtonClick4} className="hero-btn">
                      EXPLORE MORE
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* slide4 */}
        <div className="heroslidetwo  position-relative padding-four">
          <Container fluid className="ps-2 ps-md-5">
            <div className="row align-items-center">
              <div className="col-md-6 order-1 order-md-2">
                <img
                  className="img-fluid mt-4 mt-sm-3 mt-md-0 hero-image"
                  src={hero1}
                  alt=""
                />
              </div>
              <div className="col-md-6 text-center order-2 order-md-1 ">
                <div className="d-flex flex-column gap-3 text-center flex-wrap hero1-inner-left">
                  <h1 className="h4-typo" id="headhero2">
                    Unearth the essence of Sandalwood elegance
                  </h1>
                  <p className="p-typo sbhead" id="phero2">
                    Indulge in the alluring world of sandalwood with Sandaura
                    and experience the epitome of natural luxury that only
                    sandalwood can offer.{" "}
                  </p>
                  {/* <div className="text-lg-start text-md-center">
                  <a onClick={handleButtonClick1} className="hero-btn">
                    EXPLORE MORE
                  </a>
                </div> */}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Carousel>
    </div>
  );
};

export default Hero;

import React from "react";
import "./Offers.css";
import { useNavigate } from "react-router-dom";

export const Offers = () => {
  const navigate = useNavigate();

  const handleClick1 = () => {
    window.scrollTo(0, 0);
    navigate(`/Services`);
  };
  const handleClick2 = () => {
    window.scrollTo(0, 0);
    navigate(`/Products`);
  };

  return (
    <section className="offer_main">
      {/* <div className="container"> */}
        <div className="row offers justify-content-center ">
          <div className="col-12 col-md-6 col-sm-12">
            <div className="offers-right">
              <h3 className="service">Services</h3>
              <a onClick={handleClick1} className="offerbtn btn btn-right">
                EXPLORE MORE
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12">
            <div className="offers-left">
              <h3 className="producthm">Products</h3>
              <a onClick={handleClick2} className="offerbtn btn btn-left">
                EXPLORE MORE
              </a>
            </div>
          </div>
        </div>
      {/* </div> */}
    </section>
  );
};

export default Offers;

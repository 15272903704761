import React from 'react'
import Hero from './Hero'
import About from './About'
import Offers from './Offers'
import Freebeauty from './FreeBeauty'
import Categories from './Categories'
import Handicrafts from './Handicrafts'
import TissueBanner from './TissueBanner'
import Testimonial from './Testimonial'
import ServicesBooklet from './ServicesBooklet'
import FeaturesArea from './FeaturesArea'
import ServicesBanner from './ServiceBanner'

const SubHome = () => {
  return (
    <div>
        <Hero/>
        {/* <About showAboutMainpg={true} /> */}
        <FeaturesArea/>
        <Offers/>
        <ServicesBanner/>
        <ServicesBooklet/>
        {/* <Freebeauty/> */}
        <Categories/>
        <Handicrafts/>
        <TissueBanner/>
        <Testimonial/>
    </div>
  )
}

export default SubHome
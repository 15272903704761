import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Layout from "./../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";
import AddAddressPopup from "./AddAddressPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import "../Styles/CheckoutPage.css";

const CheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
  const [discount, setDiscount] = useState(location.state?.discount || 0);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressData, setAddressData] = useState([]);
  const [addressLoading, setAddressLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [formErrors, setFormErrors] = useState({ name: "", email: "" });
  const [paymentMode, setPaymentMode] = useState("Online");

  const accessToken = sessionStorage.getItem("auth");

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get(
          "https://rispl.backendapihub.com/cart/show",
          {
            headers: {
              "access-token": accessToken,
            },
          }
        );
        if (response.status === 200 && Array.isArray(response.data.data)) {
          const items = response.data.data;
          const combinedItems = items.reduce((acc, item) => {
            const existingItemIndex = acc.findIndex(
              (i) =>
                i.product_id === item.product_id &&
                i.size === item.size &&
                i.price === item.price
            );
            if (existingItemIndex !== -1) {
              acc[existingItemIndex].qty += item.qty;
              acc[existingItemIndex].amount += item.amount;
            } else {
              acc.push({ ...item });
            }
            return acc;
          }, []);

          setCartItems(combinedItems);
        } else {
          setError("Failed to fetch cart items.");
        }
      } catch (error) {
        setError("Error fetching cart items.");
      }
    };

    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          "https://rispl.backendapihub.com/address/getall",
          {
            headers: {
              "access-token": accessToken,
            },
          }
        );
        if (response.status === 200 && response.data.status === "success") {
          const addresses = response.data.data || [];
          setAddressData(addresses);

          if (addresses.length > 0) {
            setSelectedAddress(addresses[0]);
          }
        } else {
          setError("Failed to fetch addresses.");
        }
      } catch (error) {
        setError("Error fetching addresses.");
      } finally {
        setAddressLoading(false);
      }
    };

    fetchCartItems();
    fetchAddresses();

    // Fetch user profile to prefill the form
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          "https://rispl.backendapihub.com/profile/view",
          {
            headers: {
              "access-token": accessToken,
            },
          }
        );
        if (response.status === 200 && response.data.status === "Success") {
          const profileData = response.data.data[0];
          setFormData({
            name: profileData.name || "",
            email: profileData.email || "",
          });
        }
      } catch (error) {
        setError("Failed to fetch profile.");
      }
    };
    fetchProfile();
  }, []);

  const calculateSubtotal = () =>
    cartItems.reduce((acc, item) => acc + (item.amount || 0), 0);

  const calculateTotal = () => calculateSubtotal() - discount;

  const validateForm = () => {
    const errors = {};

    if (!formData.name) errors.name = "Name is required";

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    // if (!formData.mobile) {
    //   errors.mobile = "Mobile number is required";
    // } else if (!/^[6-9]\d{9}$/.test(formData.mobile)) { // Indian mobile number format
    //   errors.mobile = "Invalid mobile number";
    // }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async () => {
    if (!validateForm()) {
      toast.error("Please correct the errors.");
      return;
    }

    try {
      const response = await axios.post(
        "https://rispl.backendapihub.com/profile/update",
        {
          name: formData.name,
          email: formData.email,
          // mobile: formData.mobile,
        },
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Profile updated successfully!");
        setIsFormModalOpen(false);
        handleCheckout();
      } else {
        toast.error("Failed to update profile.");
      }
    } catch (error) {
      toast.error("Error updating profile.");
    }
  };

  const handleCheckout = async () => {
    if (!validateForm()) {
      setIsFormModalOpen(true);
      return;
    }
  
    try {
      if (!selectedAddress) {
        toast.error("Please select a delivery address.");
        return;
      }
  
      const payload = {
        shippingCharges: 0,
        addressID: selectedAddress.id,
        promocode: discount,
      };
  
      if (paymentMode === "COD") {
        payload.paymentMode = "COD";
      }
  
      const response = await axios.post(
        "https://rispl.backendapihub.com/payments/razorpay/checkout",
        payload,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
  
      if (response.status === 200 && response.data.status === "success") {
        if (paymentMode === "COD") {
          toast.success("Order placed successfully with Cash on Delivery!");
          navigate("/order-confirmed");
        } else {
          const paymentLink = response.data.data[0].payment_link;
          window.location.href = paymentLink;
          toast.success("Redirecting to payment...");
        }
      } else {
        toast.error("Failed to proceed with checkout.");
      }
    } catch (error) {
      toast.error("Error during checkout.");
    }
  };
  

  const handleDeleteAddress = (addressId, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this address?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.delete(
                `https://rispl.backendapihub.com/address/delete/${addressId}`,
                {
                  headers: {
                    accept: "application/json",
                    "access-token": accessToken,
                  },
                }
              );

              if (
                response.status === 200 &&
                response.data.status === "success"
              ) {
                const updatedAddresses = addressData.filter(
                  (_, i) => i !== index
                );
                setAddressData(updatedAddresses);
                if (updatedAddresses.length === 0) {
                  setAddressLoading(true);
                  setTimeout(() => {
                    setAddressLoading(false);
                  }, 1000);
                }
                toast.success("Address deleted successfully.");
              } else {
                toast.error("Failed to delete address.");
              }
            } catch (error) {
              toast.error("Error deleting address.");
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleAddAddress = (newAddress) => {
    setAddressData((prevAddresses) => [...prevAddresses, newAddress]);
    setIsPopupOpen(false);
    window.location.reload();
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  const openFormModal = () => {
    setIsFormModalOpen(true);
  };

  const renderAddresses = () => {
    if (addressLoading) return <div>Loading...</div>;

    if (addressData.length === 0) {
      return (
        <p className="no-address-message">No shipping addresses available</p>
      );
    }

    return (
      <div>
        {addressData.length > 0 && (
          <>
            <div className="address-title">DEFAULT ADDRESS</div>
            <div className="address-card">
              <label className="radio-label">
                <input
                  type="radio"
                  name="address"
                  value={addressData[0].id}
                  checked={selectedAddress?.id === addressData[0].id}
                  onChange={() => handleSelectAddress(addressData[0])}
                  className="radio-input"
                />
                <span className="user-name">{formData.name}</span>
              </label>
              <p>
                {addressData[0].address1}, {addressData[0].address2}{" "}
                {addressData[0].city}, {addressData[0].state} -{" "}
                {addressData[0].pincode}
              </p>
              <button
                className="delete-btn"
                aria-label="Delete Address"
                onClick={() =>
                  handleDeleteAddress(
                    addressData[0].id,
                    addressData.findIndex((a) => a.id === addressData[0].id)
                  )
                }
              >
                <DeleteIcon /> Delete
              </button>
            </div>
          </>
        )}
        {addressData.length > 1 && (
          <>
            <div className="address-title">OTHER ADDRESSES</div>
            {addressData.slice(1).map((address) => (
              <div key={address.id} className="address-card">
                <label className="radio-label">
                  <input
                    type="radio"
                    name="address"
                    value={address.id}
                    checked={selectedAddress?.id === address.id}
                    onChange={() => handleSelectAddress(address)}
                    className="radio-input"
                  />
                  <span className="user-name">{formData.name}</span>
                </label>
                <p>
                  {address.address1}, {address.address2} {address.city},{" "}
                  {address.state} - {address.pincode}
                </p>
                <button
                  className="delete-btn"
                  aria-label="Delete Address"
                  onClick={() =>
                    handleDeleteAddress(
                      address.id,
                      addressData.findIndex((a) => a.id === address.id)
                    )
                  }
                >
                  <DeleteIcon /> Delete
                </button>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="checkout-bg row position-relative"></div>
      <div className="checkout-page">
        <div className="checkout-card">
          <div className="shipping-address">
            <div className="shipping-address-header">
              <h2>Shipping Address</h2>
            </div>
            <div className="shipping-address-content">{renderAddresses()}</div>

            <button
              className="add-address-button"
              onClick={() => setIsPopupOpen(true)}
            >
              + Add New Address
            </button>
          </div>

          <div className="cart-summary">
            <h2>Order Summary</h2>
            <div className="order-summary">
              <p>
                <span>Subtotal</span>{" "}
                <span>₹ {calculateSubtotal().toFixed(2)}</span>
              </p>
              <p>
                <span>Shipping</span> <span>Free</span>
              </p>
              <p>
                <span>Coupon Discount</span>{" "}
                <span>₹ {discount.toFixed(2)}</span>
              </p>
              <p className="total">
                <span>Total Amount</span>{" "}
                <span>₹ {calculateTotal().toFixed(2)}</span>
              </p>
            </div>
            <div className="payment-mode-wrapper">
              <h3>Choose Payment Method</h3> {/* Added title here */}
              <div className="payment-mode">
                <label className="payment-label">
                  <input
                    type="radio"
                    name="paymentMode"
                    value="COD"
                    checked={paymentMode === "COD"}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  />
                  Cash on Delivery
                </label>
              </div>
              <div className="payment-mode">
                <label className="payment-label">
                  <input
                    type="radio"
                    name="paymentMode"
                    value="Online"
                    checked={paymentMode === "Online"}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  />
                  Pay Now
                </label>
              </div>
            </div>

            <button className="checkout-button" onClick={handleCheckout}>
              {paymentMode === "COD" ? "Place Order" : "Continue With Payment"}
            </button>
          </div>
        </div>
      </div>
      <Footer showBothDivs={false} />
      <Modal
        isOpen={isFormModalOpen}
        onRequestClose={() => setIsFormModalOpen(false)}
        className="modal-content-pro"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div>
          <h2>Update Name and Email</h2>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
            />
            {formErrors.name && <p className="error-text">{formErrors.name}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            {formErrors.email && (
              <p className="error-text">{formErrors.email}</p>
            )}
          </div>
          {/* <div className="form-group">
            <label htmlFor="tel">Phone Number:</label>
            <input
              type="tel"
              id="tel"
              value={formData.mobile}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, mobile: e.target.value }))
              }
            />
            {formErrors.mobile && <p className="error-text">{formErrors.mobile}</p>}
          </div> */}
          <button className="btns btn-primarys" onClick={handleFormSubmit}>
            Save and Proceed
          </button>
          <button
            className="btns btn-secondaryc"
            onClick={() => setIsFormModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <AddAddressPopup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onAddAddress={handleAddAddress}
      />
    </Layout>
  );
};

export default CheckoutPage;

import React, { useState } from "react";
import "../Styles/Productpagemain.css";
import Har_Ghar_Chandan_2 from "../Assets/Har_Ghar_Chandan_2.jpg";
import Gift_Sandalwood_plant from "../Assets/Gift_Sandalwood_plant.jpg";
import Unique_Gifting_1 from "../Assets/Unique_Gifting_1.jpg";
import Customized_Handicrafts from "../Assets/Customized_Handicrafts.jpg";
import ProductsDisplay_Image from "../Assets/ProductsDisplay_Image1.jpg";
import Sandaura_Logo from "../Assets/Sandaura_Logo.png";
import defaultImage from "../Assets/image_not_found.svg";
import Tissue_culture from "../Assets/Tissue_culture.jpg";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";
import { useNavigate } from "react-router-dom";

const Productpagemain = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  const handleBannerClick = () => {
    window.scrollTo(0, 0);
    navigate(`/Gifting`);
  };

  const goTogiftPlant = () => {
    navigate(`/product/${65}`);
  };
  const goTogiftBox = () => {
    navigate(`/product/${83}`);
  };

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true} textColor="white">
      <div className="Productpagemain-Plantation-bg row position-relative">
        {/* <img src={Sandaura_Logo} alt="Sandaura Logo" /> */}
        </div>
      <div className="Productpagemain-section">
        <div className="Productpagemain-container">
          <img
            className="Productpagemain-image"
            src={Har_Ghar_Chandan_2}
            alt="plant"
            onError={handleImageError}
          />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">Har Ghar Chandan</h2>
            <p className="Productpagemain-description">
              Many people are unaware and find it hard to believe that
              sandalwood can be successfully cultivated in a pot at home. <br />
              Sandalwood, a cherished part of our heritage, is believed to bring
              good luck, positive energy and wealth to our homes.
            </p>
            <button
              className="know-more-btn"
              onClick={handleBannerClick}
              style={{
                backgroundColor: "rgba(237, 189, 103, 1)",
                border: "none",
              }}
            >
              View Details
            </button>
          </div>
        </div>
        <div className="Productpagemain-container">
          <img
            className="Productpagemain-image"
            src={Gift_Sandalwood_plant}
            alt="Gift Sandalwood plant"
            onError={handleImageError}
          />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">Gift a Sandalwood Plant</h2>
            <p className="Productpagemain-description">
              Gift a sandalwood plant and contribute to a greener future,
              providing a unique and valuable present that grows in both
              significance and worth.
            </p>
            <button
              className="know-more-btn"
              style={{
                backgroundColor: "rgba(237, 189, 103, 1)",
                border: "none",
              }}
              onClick={goTogiftPlant}
            >
              View Details
            </button>
            (Bulk orders only)
          </div>
        </div>
        <div className="Productpagemain-container">
          <img
            className="Productpagemain-image"
            src={Unique_Gifting_1}
            alt="Gifting"
            onError={handleImageError}
          />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">
              Unique Gifting Sandalwood Products
            </h2>
            <p className="Productpagemain-description">
              Impress clients and partners with premium sandalwood products,
              offering a touch of elegance and lasting value in your Unique
              gifts.
            </p>
            <button
              className="know-more-btn"
              style={{
                backgroundColor: "rgba(237, 189, 103, 1)",
                border: "none",
              }}
              onClick={goTogiftBox}
            >
              View Details
            </button>
          </div>
        </div>
        <div className="Productpagemain-coming-soon-section">
          <h2 className="coming-soon-title">Coming Soon</h2>
          <div className="Productpagemain-container">
            <img
              className="Productpagemain-image"
              src={Customized_Handicrafts}
              alt="Customized Handicrafts"
              onError={handleImageError}
            />
            <div className="Productpagemain-text-container">
              <h2 className="Productpagemain-title">Customized Handicrafts</h2>
              <p className="Productpagemain-description">
                We customize the handicrafts to meet your specific needs.
              </p>
              <button className="know-more-btn" disabled>
                Coming Soon
              </button>
            </div>
          </div>
          <div className="Productpagemain-container">
            <img
              className="Productpagemain-image"
              src={ProductsDisplay_Image}
              alt="All Products"
              onError={handleImageError}
            />
            <div className="Productpagemain-text-container">
              <h2 className="Productpagemain-title">Products</h2>
              <p className="Productpagemain-description">
                Indulge in the alluring world of sandalwood with Sandaura
                products.
              </p>
              <button className="know-more-btn" disabled>
                Coming Soon
              </button>
            </div>
          </div>
          <div className="Productpagemain-container">
            <img
              className="Productpagemain-image"
              src={Tissue_culture}
              alt="Villa"
              onError={handleImageError}
            />
            <div className="Productpagemain-text-container">
              <h2 className="Productpagemain-title">Tissue Culture</h2>
              <p className="Productpagemain-description">
                Genetically superior Tissue cultured Sandalwood plants.
              </p>
              <button className="know-more-btn" disabled>
                Coming Soon
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Productpagemain;

import React, { useState } from "react";
import "../../Styles/SandalwoodHeartwoodAssessment.css";
import servicespage1 from "../.././Assets/Sandalwood_heartwood_assessment.jpeg";
import leaf from "../.././Assets/gift-vector-leaf.png";
import bookFlipPdf from "../.././Assets/Services-booklet.pdf";
import Layout from "../Layout/Layout";
import Footer from "../Layout/Footer";
import { Container, Row } from "react-bootstrap";


const SandalwoodHeartwoodAssessment = () => {
  const [cartItems, setCartItems] = useState([]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf;
    link.download = "Services-booklet.pdf";
    link.click();
  };

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="Heartwood-container">
        <div className="container">
          <div className="Heartwood-text">
            {/* <span className="Sandalwood-text1">Farmland</span>{" "} */}
            <span className="Heartwood-text2">Heartwood Assessment</span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="Heartwood-info-content-wrapper">
          <img src={leaf} alt="leaf" className="Heartwood-leaf" />
          <Row>
            <div className="col-lg-7 col-sm-12 col-12 p-2">
              <div className="Heartwood-info-content">
                <h2>Heartwood Assessment</h2>
                <p>
                  Sandalwood harvesting is a careful process that involves cutting
                  mature trees, typically aged 15 years or more, to ensure the
                  highest quality of heartwood. The heartwood, the dense inner part
                  of the tree, is prized for its fragrance and is used in various
                  products, including essential oils and incense.
                  <br />
                  During harvesting, it's essential to assess the heartwood to
                  determine its quality. This assessment involves evaluating factors
                  such as:
                  <ul style={{ paddingLeft: "0px" }}>
                    <li>
                      <strong>Diameter and Height:</strong> The size of the tree can
                      indicate the potential yield of heartwood.
                    </li>
                    <li>
                      <strong>Color and Texture:</strong> High-quality heartwood is
                      typically dark and has a fine, even texture.
                    </li>
                    <li>
                      <strong>Fragrance:</strong> The intensity and quality of the
                      scent are key indicators of heartwood quality.
                    </li>
                    <li>
                      <strong>Moisture Content:</strong> Proper drying methods are
                      crucial to preserve the heartwood's properties.
                    </li>
                  </ul>
                  Sustainable harvesting practices are vital to ensure the longevity
                  of sandalwood resources, emphasizing the importance of replanting
                  and responsible management.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12 col-12 p-2">
              <img
                src={servicespage1}
                alt="Right Image"
                className="img-fluid"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </div>
          </Row>
        </div>
      </div>

      <section className="mb-80">
        <Container>
          <div className="farmland-info-bottom">
            <div className="farmland-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button
              className="learn-more-button"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
        </Container>
      </section>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default SandalwoodHeartwoodAssessment;

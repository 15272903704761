import React from "react";
// import { useNavigate } from "react-router-dom";
import "./Handicrafts.css";
import { Container } from "react-bootstrap";

export const Handicrafts = () => {
  // const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   window.scrollTo(0, 0);
  //   navigate("/products");
  // };

  return (
    <div className="Handicrafts-main">
      <div className="Handicrafts-first">
        <Container className="Handicrafts-content">
          <h1 className="mb-3">Customized Handicrafts</h1>
          <p className="mb-3">
            We customize the handicrafts to meet your specific needs.
          </p>
          <button className="Handicrafts-btn mt-4" disabled>
          Coming Soon
          </button>
        </Container>
      </div>
    </div>
  );
};

export default Handicrafts;

import React, { useState } from "react";
import Layout from '../Components/Layout/Layout'
import SubHome from '../Components/Sub_Home/SubHome'
// import { useAuth } from '../Context/Auth'

const HomePage = () => {
  // const { accessToken, login, logout } = useAuth();
  const [cartItems, setCartItems] = useState([]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isFooter={true} isNavbar={true}> 
      <SubHome/>
    </Layout>
  )
}

export default HomePage
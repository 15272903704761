import React, { useState } from "react";
import "../../Styles/SandalwoodMaintenance.css";
import servicespage1 from "../.././Assets/assessment_5.jpg";
import leaf from "../.././Assets/gift-vector-leaf.png";
import bookFlipPdf from "../.././Assets/Services-booklet.pdf";
import Layout from "../Layout/Layout";
import Footer from "../Layout/Footer";
import { Container, Row } from "react-bootstrap";


const SandalwoodMaintenance = () => {
  const [cartItems, setCartItems] = useState([]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf;
    link.download = "Services-booklet.pdf";
    link.click();
  };

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="Maintenance-container">
        <div className="container">
          <div className="Maintenance-text">
            {/* <span className="Sandalwood-text1">Farmland</span>{" "} */}
            <span className="Maintenance-text2">Maintenance</span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="Maintenance-info-content-wrapper">
          <img src={leaf} alt="leaf" className="Maintenance-leaf" />
          <Row>
            <div className="col-lg-7 col-sm-12 col-12 p-2">
              <div className="Maintenance-info-content">
                <h2>Maintenance</h2>
                <p>
                  Once the Sandalwood plantation phase is complete, we initiate
                  maintenance by signing an annual maintenance contract with our
                  client. If the client wishes to handle maintenance themselves and
                  only requires our consulting services, we are happy to accommodate
                  that as well. Maintenance tasks include watering the plants
                  according to a scheduled frequency, clearing the land of unwanted
                  weeds and grass, pruning the trees, applying fertilizer, and using
                  insecticides or pesticides as needed.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12 col-12 p-2">
              <img
                src={servicespage1}
                alt="Right Image"
                className="img-fluid"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </div>
          </Row>
        </div>
      </div>

      <section className="mb-80">
        <Container>
          <div className="farmland-info-bottom">
            <div className="farmland-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button
              className="learn-more-button"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
        </Container>
      </section>


      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default SandalwoodMaintenance;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../Styles/OrderConfirmedPageCod.css"; 
import Layout from '../Components/Layout/Layout';
import Footer from '../Components/Layout/Footer';

const OrderConfirmedPage = () => {
 const location = useLocation();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="success-bg row position-relative"></div>
    <div className="order-confirmation-page">
      <div className="order-confirmation-popup">
        <div className="success-icon">&#10004;</div>
        <div className="confirmation-message">
          <h2>Order Confirmed</h2>
          <p>Your order is confirmed. You will receive an order confirmation SMS shortly.</p>
        </div>
        <button
          className="go-to-order-history-button"
          onClick={() => navigate("/dashboard/user/orderhistory")}
        >
          Go to Order History
        </button>
      </div>
    </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default OrderConfirmedPage;

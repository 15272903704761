import React, { useState } from "react";
import "../../Styles/SandalwoodFarmlandPlantation.css";
import servicespage1 from "../../Assets/Sandalwood_plantation_plants.jpg";
import leaf from "../../Assets/gift-vector-leaf.png";
import bookFlipPdf from "../../Assets/Services-booklet.pdf";
import Layout from "../Layout/Layout";
import Footer from "../Layout/Footer";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "react-bootstrap";


const SandalwoodFarmlandPlantation = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = bookFlipPdf;
    link.download = "Services-booklet.pdf";
    link.click();
  };

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="Sandalwood-farmland-container">
        <div className="container">
          <div className="Sandalwood-farmland-text">
            <span className="Sandalwood-text1">Farmland</span>
            <span className="Sandalwood-text2">Sandalwood Plantation</span>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="Sandalwood-farmland-info-content-wrapper">
          <div className="Sandalwood-link-section">
            <span
              className="Sandalwood-link"
              onClick={() => handleNavigate("/SandalwoodPlantation")}
              style={{ width: "32%" }}
            >
              Sandalwood Plantation
            </span>
            <span className="separator">•</span>{" "}
            <span
              className="Sandalwood-link"
              onClick={() => handleNavigate("/Villa_Bunglow")}
            >
              Villa/Bunglow
            </span>
            <span className="separator">•</span>{" "}
            <span
              className="Sandalwood-link"
              onClick={() => handleNavigate("/gifting")}
            >
              Har Ghar Chandan
            </span>
          </div>
          <img src={leaf} alt="leaf" className="Sandalwood-leaf" />
          <Row>
            <div className="col-lg-7 col-sm-12 col-12 p-2">
              <div className="Sandalwood-farmland-info-content">
                <h2>Farmland Sandalwood Plantation</h2>
                <p>
                  We cover the overall aspect of Sandalwood planting from soil
                  analysis, soil preparation, fertilization, plant selection,
                  sandalwood and host plantation, irrigation and the final training
                  program. <br /> We do sandalwood planting for farmers,
                  individuals, and companies. We undertake the implementation of
                  sandalwood planting projects for the private or public sector
                  including government- owned businesses or corporations. <br /> The
                  quality of the sandalwood plants available in our nursery is far
                  superior, ensuring a high yield of heartwood at maturity over
                  time. Sandalwood is parasitic in nature and feeds on a suitable
                  host plant. Therefore, our company specializes in the selection of
                  the best host plant according to individual soil and climatic
                  conditions.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12 col-12 p-2">
              <img
                src={servicespage1}
                alt="Right Image"
                className="img-fluid"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </div>
          </Row>
        </div>
      </div>

      <section className="mb-80">
        <Container>
          <div className="farmland-info-bottom">
            <div className="farmland-info-bottom-left">
              <span>HAVE A LOOK</span>
              <h3>Services booklet</h3>
            </div>
            <button
              className="learn-more-button"
              onClick={handleDownload}
            >
              Download
            </button>
          </div>
        </Container>
      </section>

      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default SandalwoodFarmlandPlantation;

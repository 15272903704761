import React, { useEffect, useRef, useState } from "react";
import "./Categories.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left_button from "../../Assets/left_arrow.png";
import right_button from "../../Assets/right_arrow.png";
import category_flower from "../../Assets/category-flower-img.png";
import defaultImage from "../../Assets/image_not_found.svg";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

export const Categories = () => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);



  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://rispl.backendapihub.com/product/get/categories"
      );
      if (response.data.status === "success") {
        setCategories(response.data.data);
      } else {
        console.error("Failed to fetch categories.");
      }
    } catch (error) {
      console.error("Error fetching categories:", error.response || error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchCategories();
  }, []);

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const handleClick = categoryName => {
    window.scrollTo(0, 0);
    if (categoryName.toLowerCase() === "gifting") {
      navigate("/gifting");
    } else {
      navigate("/products");
    }
  };

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  const settings = {
    autoplay: true,
    dots: false,
    className: "center",
    centerPadding: "10px",
    infinite: true,
    interval: 3000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      style={{ position: "relative" }}
      className="cattmain position-relative"
    >
      <img className="category_flower" src={category_flower} alt="" />

      <Container className="ps-2 ps-sm-4" fluid>
        <Row>
          <Col xs={12} sm={12} md={3}>
            <div className="left-container">
              <h2>
                Our <br /> Categories
              </h2>
              <h3>
                Explore our wide range of categories <br /> of sandalwood
              </h3>
              <div className="category-btn">
                <img src={left_button} alt="arrow" onClick={previous} />
                <img src={right_button} alt="arrow" onClick={next} />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9}>
            <div className="right-container ctg">
              {loading ? (
                <div className="loading-spinner-category">
                  <CircularProgress />
                </div>
              ) : (
                <Slider className="sliderr" ref={sliderRef} {...settings}>
                  {categories.map(category => (
                    <div
                      className="Card"
                      key={category.categoryID}
                      onClick={() => handleClick(category.category_name)}
                    >
                      <img
                        id="catcrd"
                        src={`https://rispl.backendapihub.com/images/${category.media[0] || defaultImage }`}
                        alt={category.category_name}
                        className="img-fluid"
                        onError={handleImageError}
                      />
                      <div className="cardbody">
                        <h3>{category.category_name}</h3>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Categories;

import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";
import Foooter from "../../Assets/Footer.jpg";
// import footer_mob from "../../Assets/footer_mob.png";
import Footer_logo from "../../Assets/Footerlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = ({ showBothDivs }) => {
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="Footer-main">
      {showBothDivs ? (
        <>
          <div className="Footer-img abc">
            <h1>MOU with Sandalwood Society Of India</h1>
            <p>
              Royal Indian Sandalwood Private Limited has partnered with
              Sandalwood Society
              <br /> Of India to advance the Sandalwood industry.
            </p>
          </div>

          <div className="content-footer">
            <div className="footer-logo">
              <img src={Footer_logo} alt="logo" />
            </div>

            <div className="footer-nav">
              <ul className="footer-nav-menu">
                <NavLink
                  to="/"
                  className="footer-navline1"
                  onClick={scrollToTop}
                >
                  HOME
                </NavLink>
                <NavLink
                  to="/about"
                  className="footer-navline1"
                  onClick={scrollToTop}
                >
                  ABOUT
                </NavLink>
                <NavLink
                  to="/Services"
                  className="footer-navline2"
                  onClick={scrollToTop}
                >
                  SERVICES
                </NavLink>
                <NavLink
                  to="/products"
                  className="footer-navline1"
                  onClick={scrollToTop}
                >
                  PRODUCTS
                </NavLink>
                <NavLink
                  to="/gifting"
                  className="footer-navline2"
                  onClick={scrollToTop}
                >
                  HAR GHAR CHANDAN
                </NavLink>
                <NavLink
                  to="/Contact"
                  className="footer-navline2"
                  onClick={scrollToTop}
                >
                  CONTACT US
                </NavLink>
              </ul>
            </div>
            <div className="footer-social-media">
              <FontAwesomeIcon icon={faFacebook} />
              <FontAwesomeIcon icon={faInstagram} />
              <FontAwesomeIcon icon={faYoutube} />
            </div>
            <div className="end-footer">
              <p>
                &copy; 2024 Royal Indian Sandalwood Private Limited. All Rights
                Reserved
              </p>
            </div>
            <div className="footer-privacy-policy-container">
              <NavLink
                to="/privacy-policy"
                className="footer-privacy-policy-link"
                onClick={scrollToTop}
              >
                Privacy Policy
              </NavLink>
              <NavLink
                to="/Return-Refund-policy"
                className="footer-privacy-policy-link"
                onClick={scrollToTop}
              >
                Return & Refund Policy
              </NavLink>
              <NavLink
                to="/Terms-Conditions-policy"
                className="footer-Return-Refund-link"
                onClick={scrollToTop}
              >
                Terms and Conditions
              </NavLink>
            </div>
          </div>
        </>
      ) : (
        <div className="content-footer">
          <div className="footer-logo">
            <img src={Footer_logo} alt="logo" />
          </div>
          <div className="footer-nav">
            <ul className="footer-nav-menu d-flex">
              <NavLink to="/" className="footer-navline1" onClick={scrollToTop}>
                HOME
              </NavLink>
              <NavLink
                to="/about"
                className="footer-navline1"
                onClick={scrollToTop}
              >
                ABOUT
              </NavLink>
              <NavLink
                to="/Services"
                className="footer-navline2"
                onClick={scrollToTop}
              >
                SERVICES
              </NavLink>
              <NavLink
                to="/products"
                className="footer-navline1"
                onClick={scrollToTop}
              >
                PRODUCTS
              </NavLink>
              <NavLink
                to="/gifting"
                className="footer-navline2"
                onClick={scrollToTop}
              >
                HAR GHAR CHANDAN
              </NavLink>
              <NavLink
                to="/Contact"
                className="footer-navline2"
                onClick={scrollToTop}
              >
                CONTACT US
              </NavLink>
            </ul>
          </div>
          <div className="footer-social-media">
            <FontAwesomeIcon icon={faFacebook} />
            <FontAwesomeIcon icon={faInstagram} />
            <FontAwesomeIcon icon={faYoutube} />
          </div>
          <div className="end-footer">
            <p>
              &copy; 2024 Royal Indian Sandalwood Private Limited. All Rights
              Reserved
            </p>
          </div>
          <div className="footer-privacy-policy-container">
            <NavLink
              to="/privacy-policy"
              className="footer-privacy-policy-link"
              onClick={scrollToTop}
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/Return-Refund-policy"
              className="footer-privacy-policy-link"
              onClick={scrollToTop}
            >
              Return & Refund Policy
            </NavLink>
            <NavLink
              to="/Terms-Conditions-policy"
              className="footer-Return-Refund-link"
              onClick={scrollToTop}
            >
              Terms and Conditions
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;

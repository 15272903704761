import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import logoSrc from "../../Assets/whiteLogo.png";
// import Logo_Animation from "../../Assets/Logo_Animation.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowClose,
  faPlay,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "./UserTooltip";
import "./Navbar.css";

const Navbar = ({ cartCount, showNormalNav, textColor }) => {
  const [menu, setMenu] = useState("home");
  const [isVisible, setIsVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const textClass = textColor === "white" ? "whiteText" : "blackText";

  useEffect(() => {
    const authData = sessionStorage.getItem("auth");
    setIsLoggedIn(!!authData);
  }, [location]);

  const handleLogout = () => {
    sessionStorage.removeItem("auth");
    sessionStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/login");
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsVisible(
        currentScrollPos <= 0 || currentScrollPos < window.innerHeight
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {}, []);

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const closeModal = () => setShowModal(false);



  const userLinks = isLoggedIn
    ? [
        { to: "/dashboard/user/profile", label: "Profile" },
        { to: "/dashboard/user/orderhistory", label: "Orders" },
        { to: "/dashboard/user/wishlist", label: "Wishlist" },
        { to: "#", label: "Logout", onClick: handleLogout },
      ]
    : [{ to: "/login", label: "Login" }];

  return (
    <>
      {showModal && (
        <div className="modal-overlay-about">
          <div className="modal-content-about">
            <video autoPlay controls className="video-element-about">
                {/* <source src={Logo_Animation} type="video/mp4" />  */}
              </video>
              <button className="Close-btn-about" onClick={closeModal}>
              <FontAwesomeIcon icon={faWindowClose}  className="Close-button-about"/>
            </button>
          </div>
        </div>
      )}

      <div>
        {showNormalNav ? (
          <>
            {/* Top Navigation */}
            <div
              className={`d-flex justify-content-center align-items-center top-nav ${
                isVisible ? "" : "hide"
              }`}
            >
              <p className="m-0">
                SANDAURA is derived from the word ‘SANDALWOOD’.
              </p>
            </div>

            {/* Main Navbar */}
            <nav className="navbar navbar-expand-lg p-0">
              <div className="container-lg justify-content-around">
                <div className="brand-menu">
                  <div className="logo-main">
                    <a
                      className="navbar-brand"
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setShowModal(true);
                      }}
                    >
                      <img
                        className="m-0"
                        src={textColor === "white" ? logoSrc : logo}
                        alt="logo"
                      />
                      <div className="play-button">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </a>
                  </div>
                  <button
                    className={`d-lg-none navbar-toggler collapsed d-flex flex-column ${textClass} ${
                      textColor === "white" ? "toggler-white" : ""
                    }`}
                    type="button"
                    aria-expanded={menuOpen}
                    aria-label="Toggle navigation"
                    onClick={toggleMenu}
                  >
                    <span className="toggler-icon top-bar"></span>
                    <span className="toggler-icon middle-bar"></span>
                    <span className="toggler-icon bottom-bar"></span>
                  </button>
                </div>
                <div>
                  <div
                    className={`collapse navbar-collapse custom-nav align-items-center ${
                      menuOpen ? "show" : ""
                    }`}
                    id="navbarNav"
                  >
                    <ul className={`navbar-nav ${textClass}`}>
                      <li
                        onClick={() => setMenu("home")}
                        className={`nav-item px-1 ${
                          menu === "home" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/"
                          className={`nav-link  ${textClass}`}
                          aria-current="page"
                        >
                          HOME
                        </NavLink>
                      </li>
                      <li
                        onClick={() => setMenu("about")}
                        className={`nav-item px-1 ${
                          menu === "about" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/about"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          ABOUT
                        </NavLink>
                      </li>
                      <li
                        onClick={() => setMenu("service")}
                        className={`nav-item px-1 ${
                          menu === "service" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/Services"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          SERVICES
                        </NavLink>
                      </li>
                      <li
                        onClick={() => setMenu("products")}
                        className={`nav-item px-1 ${
                          menu === "products" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/products"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          PRODUCTS
                        </NavLink>
                      </li>

                      <li
                        onClick={() => setMenu("gifting")}
                        className={`nav-item px-1 ${
                          menu === "gifting" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/gifting"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          Har Ghar Chandan
                        </NavLink>
                      </li>
                      <li
                        onClick={() => setMenu("Contact")}
                        className={`nav-item px-1 ${
                          menu === "Contact" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/Contact"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          Contact Us
                        </NavLink>
                      </li>

                      <div
                        className="d-lg-flex justify-content-around mt-2 nav-right"
                        style={{ gap: "4rem" }}
                      >
                        <Tooltip
                          icon={<FontAwesomeIcon icon={faUser} />}
                          links={userLinks}
                        />
                      </div>
                    </ul>
                  </div>
                </div>

                <NavLink to="/cart" className={`nav-link ${textClass}`}>
                  <div
                    className="cart-container"
                    // aria-label="User actions"
                    // role="tooltip"
                  >
                    <div className="cart-icon">
                      <FontAwesomeIcon
                        icon={faShoppingBag}
                        style={{
                          fontSize: "20px",
                          color: textColor === "white" ? "#fff" : "#000",
                        }}
                      />
                      <span>{cartCount}</span>
                    </div>
                  </div>
                </NavLink>
              </div>
            </nav>
          </>
        ) : (
          <nav
            className="navbar navbar-expand-lg p-0"
            style={{ background: "#f1c40f", height: "80px" }}
          >
            <div className="container-lg justify-content-around">
              <div className="brand-menu">
                <div className="logo">
                  <a
                    className="navbar-brand"
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setShowModal(true);
                    }}
                  >
                    <img className="m-0" src={logoSrc} alt="" />
                  </a>
                </div>
                <button
                  className={`d-lg-none navbar-toggler collapsed d-flex flex-column ${textClass} ${
                    textColor === "white" ? "toggler-white" : ""
                  }`}
                  type="button"
                  aria-expanded={menuOpen}
                  aria-label="Toggle navigation"
                  onClick={toggleMenu}
                >
                  <span className="toggler-icon top-bar"></span>
                  <span className="toggler-icon middle-bar"></span>
                  <span className="toggler-icon bottom-bar"></span>
                </button>
              </div>
              <div>
                <div
                  className={`collapse navbar-collapse custom-nav align-items-center ${
                    menuOpen ? "show" : ""
                  }`}
                  id="navbarNav"
                >
                  <ul className={`navbar-nav ${textClass}`}>
                    <li
                      onClick={() => setMenu("home")}
                      className={`nav-item px-1 ${
                        menu === "home" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        HOME
                      </NavLink>
                    </li>
                    <li
                      onClick={() => setMenu("about")}
                      className={`nav-item px-1 ${
                        menu === "about" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/about"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        ABOUT
                      </NavLink>
                    </li>
                    <li
                      onClick={() => setMenu("service")}
                      className={`nav-item px-1 ${
                        menu === "service" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/Services"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        SERVICES
                      </NavLink>
                    </li>
                    <li
                      onClick={() => setMenu("products")}
                      className={`nav-item px-1 ${
                        menu === "products" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/products"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        PRODUCTS
                      </NavLink>
                    </li>
                    <li
                      onClick={() => setMenu("gifting")}
                      className={`nav-item px-1 ${
                        menu === "gifting" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/gifting"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        Har Ghar Chandan
                      </NavLink>
                    </li>
                    <li
                      onClick={() => setMenu("Contact")}
                      className={`nav-item px-1 ${
                        menu === "Contact" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/Contact"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <div
                      className="d-lg-flex justify-content-around mt-2 nav-right"
                      style={{ gap: "4rem" }}
                    >
                      <Tooltip
                        icon={<FontAwesomeIcon icon={faUser} />}
                        links={userLinks}
                      />
                    </div>
                  </ul>
                </div>
              </div>

              <NavLink to="/cart" className={`nav-link ${textClass}`}>
                <div className="cart-icon sett">
                  <FontAwesomeIcon
                    icon={faShoppingBag}
                    style={{
                      fontSize: "20px",
                      color: textColor === "white" ? "#fff" : "#000",
                    }}
                  />
                  <span>{cartCount}</span>
                </div>
              </NavLink>
            </div>
          </nav>
        )}
      </div>
    </>
  );
};

export default Navbar;

import React, { useState } from "react";
import Layout from "../Components/Layout/Layout";
import ServicesPage from "../Components/Sub_Home/ServicesPage";

const ServicePage = () => {
  const [cartItems, setCartItems] = useState([]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isFooter={true} isNavbar={true}> 
      <div className="about-main" style={{ marginTop: 20 }}>
        <ServicesPage/>
      </div>
    </Layout>
  );
};

export default ServicePage;

import React, { useState, useEffect } from "react";
import Layout from "./../Components/Layout/Layout";
import axios from "axios";
import toast from "react-hot-toast";
import { FaPlus, FaMinus } from "react-icons/fa6";
import Footer from "../Components/Layout/Footer";
// import giftbox from "../Assets/giftbox.png";
import defaultImage from "../Assets/image_not_found.svg";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import "../Styles/CartStyles.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [error, setError] = useState(null);
  const [promoError, setPromoError] = useState("");
  const navigate = useNavigate();

  const accessToken = sessionStorage.getItem("auth");

  const fetchCartItems = async () => {
    if (!accessToken) return;
    try {
      const response = await axios.get(
        "https://rispl.backendapihub.com/cart/show",
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          setCartItems(response.data.data);
          if (response.data.data.length === 0) {
            removePromoCode();
          }
        } else {
          console.error("Cart items data is not an array");
          setCartItems([]);
        }
      } else {
        console.error("Failed to fetch cart items");
        setError("Failed to load cart items. Please try again later.");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setError("Error fetching cart items. Please try again later.");
      setCartItems([]);
    }
  };


  useEffect(() => {
    fetchCartItems();
  }, []);

  const handleQuantityChange = async (l1_id, size_id, change) => {
    const item = cartItems.find(
      (item) => item.l1_id === l1_id && item.size_id === size_id
    );

    const newQuantity = item ? item.qty + change : 0;

    if (newQuantity < 1) {
      // toast.error("Quantity cannot be less than 1.");
      return;
    }

    if (newQuantity >= 0) {
      try {
        const response = await axios.post(
          "https://rispl.backendapihub.com/cart/add",
          {
            l1_id: l1_id,
            quantity: newQuantity,
            size_id: size_id,
          },
          {
            headers: {
              "access-token": accessToken,
            },
          }
        );
        if (response.status === 200) {
          setCartItems((prevItems) =>
            prevItems.map((item) =>
              item.l1_id === l1_id && item.size_id === size_id
                ? {
                  ...item,
                  qty: newQuantity,
                  amount: newQuantity * item.price,
                }
                : item
            )
          );
        } else {
          toast.error("Failed to update the quantity.");
        }
      } catch (error) {
        console.error("Error updating quantity:", error);
        toast.error("Error updating quantity. Please try again later.");
      }
    }
  };

  const handleRemoveItem = async (id) => {
    confirmAlert({
      title: "Move from Cart",
      message: "Are you sure you want to remove this item from your cart?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.get(
                `https://rispl.backendapihub.com/cart/delete/${id}`,
                {
                  headers: {
                    "access-token": accessToken,
                  },
                }
              );
              if (response.status === 200) {
                setCartItems((prevItems) =>
                  prevItems.filter((item) => item.id !== id)
                );
                toast.success("Item removed successfully");
                if (cartItems.length === 1) {
                  removePromoCode();
                }
              } else {
                console.error("Failed to remove item from cart");
                toast.error("Failed to remove item from cart");
              }
            } catch (error) {
              console.error("Error removing item from cart:", error);
              toast.error("Error removing item from cart");
            }
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const applyPromoCode = async () => {
    try {
      const response = await axios.post(
        "https://rispl.backendapihub.com/cart/applyPromoCode",
        {
          promocode: promoCode,
          amount: calculateSubtotal(),
        },
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
  
      if (response.status === 200) {
        if (response.data.status === "success") {
          const discountAmount = response.data.data[0].amount;
  
          if (discountAmount > 0) {
            setDiscount(discountAmount);
            setIsPromoApplied(true);
            setPromoError(response.data.message); 
            toast.success(response.data.message); 
          } else {
            setDiscount(0);
            setIsPromoApplied(false);
            setPromoError("Invalid promo code."); 
            toast.error("Invalid promo code."); 
          }
        } else if (response.data.status === "failure") {
          setDiscount(0);
          setIsPromoApplied(false);
          setPromoError(response.data.message); 
          toast.error(response.data.message); 
        }
      } else {
        toast.error("Failed to apply promo code.");
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred.";
        setPromoError(errorMessage);
        toast.error(errorMessage);
      } else {
        setPromoError("An error occurred while applying the promo code.");
        toast.error("An error occurred while applying the promo code.");
      }
    }
  };
  
  const removePromoCode = () => {
    setPromoCode("");
    setDiscount(0);
    setIsPromoApplied(false);
    localStorage.removeItem("promoCode");
    localStorage.removeItem("discount");
    localStorage.removeItem("isPromoApplied");
    setPromoError("Promo code removed successfully!");
    // toast.success("Promo code removed successfully!");
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((acc, item) => acc + item.amount, 0);
  };

  const calculateDiscount = () => {
    return discount;
  };

  const calculateTotal = () => {
    return calculateSubtotal() - calculateDiscount();
  };

  const handleCheckout = () => {
    navigate("/checkout", { state: { cartItems, discount } });
    window.scrollTo(0, 0);
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
        <div className="cart-banner d-flex justify-content-center align-items-center flex-column"></div>  
      {Array.isArray(cartItems) && cartItems.length === 0 ? (
        <div className="empty-cart-container">
          <div>
            <h2>Your cart is empty</h2>
          </div>
          <div className="empty-cart-message d-flex justify-content-center align-items-center flex-column">
            <p>There is nothing in your cart. Let's add some items.</p>
          </div>
        </div>
      ) : (
        <div className="container cart-main">
          <div className="cart-head-title">
            <h1>Your Cart</h1>
          </div>
          <div className="row">
            <div className="col-md-8 order-md-1 p-0">
              <div className="cart-list-container">
                <div className="cart-list">
                  <div className="cart-list-items position-relative">
                    {cartItems.map((item) => (
                      <div key={item.id}>
                        <div className="cart-card">
                          <div className="cart-img">
                            <img
                              src={item.image ? `https://rispl.backendapihub.com/images/${item.image}` : defaultImage}
                              alt="cart-img"
                              onError={(e) => (e.target.src = defaultImage)}
                            />
                          </div>
                          <div className="cart-details">
                            <div className="cart-title-desc">
                              <h3 className="m-0">{item.product_name}</h3>
                              <div
                                className="cart-remove-option"
                                onClick={() => item.id && handleRemoveItem(item.id)}
                              >
                                Remove
                              </div>
                            </div>
                          </div>
                          <div className="cart-values">
                            <div className="labels-row">
                              <span>Price</span>
                              <span>Quantity</span>
                              <span>Total</span>
                            </div>
                            <div className="values-row">
                              <div className="price">
                                <span>₹ {item.price.toFixed(2)}</span>
                                <small className="tax-included">incl. of all taxes</small>
                              </div>
                              <div className="quantity">
                                <FaMinus
                                  className="minus"
                                  onClick={() => handleQuantityChange(item.l1_id, item.size_id, -1)}
                                />
                                <input type="text" value={item.qty} readOnly />
                                <FaPlus
                                  className="plus"
                                  onClick={() => handleQuantityChange(item.l1_id, item.size_id, 1)}
                                />
                              </div>
                              <div className="total">
                                <span>₹ {item.amount.toFixed(2)}</span>
                                <small className="tax-included">incl. of all taxes</small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="m-0 card-divider" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 order-md-2 cart-order-summary">
              <h1 className="summary-title m-0 p-0">Order Summary</h1>
              <hr />
              <div className="calculation">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="m-0 p-0">Subtotal</h3>
                  <span className="m-0 p-0">₹ {calculateSubtotal().toFixed(2)}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="m-0 p-0">Shipping</h3>
                  <span className="m-0 p-0" style={{ color: "rgba(13, 158, 0, 1)" }}>Free</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="m-0 p-0">Coupon Discount</h3>
                  <span className="m-0 p-0">₹ {calculateDiscount().toFixed(2)}</span>
                </div>
              </div>
              <hr />
              <div className="Total d-flex justify-content-between align-items-center">
                <h3 className="m-0 p-0">Total</h3>
                <span className="m-0 p-0">₹ {calculateTotal().toFixed(2)}</span>
              </div>
              <hr />
              <div className="code-wrap">
                <div className="promocode">
                  <h3 className="m-0 p-0">Discount promocode</h3>
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Enter promocode"
                      disabled={isPromoApplied}
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                    {!isPromoApplied ? (
                      <button onClick={applyPromoCode}>Apply</button>
                    ) : (
                      <button
                        onClick={removePromoCode}
                        style={{ padding: "8px 26.9px 9px 26.9px" }}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  {promoError && (
                    <p
                      style={{
                        color: isPromoApplied ? "green" : "red",
                        fontSize: "13px",
                        marginBottom: "5px",
                      }}
                    >
                      {promoError}
                    </p>
                  )}
                </div>
              </div>
              <button className="checkout-btn m-0 p-0" onClick={handleCheckout}>
                CHECKOUT
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer showBothDivs={false} />
    </Layout>

  );
};

export default CartPage;
import React from "react";
// import "./OurProduct.css";
import "./BookFlip.css";
import All_products from "../../Assets//ProductsDisplay_Image.png";
import logo from "../../Assets/logo.png";

export const OurProduct = () => {
  return (
    <div className="book">
      <div className="left-prod-imges">
        <img
          src={All_products}
          alt="All-products"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className="right-prod-info">
        <div>
          <h6
            style={{
              fontSize: "44px",
              fontWeight: "100",
            }}
          >
            OUR PRODUCTS
          </h6>
        </div>
        <div>
          <p>
            We are thrilled to introduce our latest line of exquisite sandalwood
            products, now available for you to experience luxury like never
            before, Handcrafted with precision and infused with the timeless
            allure of sandalwood, our new collection offers a range of items
            that promise to elevate your senses and enhance your daily rituals.
            Sandalwood, renowned for its aromatic richness and soothing
            properties, takes center stage in our thoughtfully curated
            selection. From enchanting sandalwood-infused perfumes that evoke a
            sense of elegance, to meticu lously designed home fragrances that
            transform your living spaces into serene sanctuaries, our products
            embody the essence of luxury and tranquility. Each item in our
            sandalwood collection is a testament to our commitment to quality
            and innovation. We have carefully sourced the finest sustainable
            sandalwood and combined it with the expertise of our artisans to
            create products that epitomize opulence while reflecting a deep
            respect for nature. Explore our new sandalwood product line and
            embark on a journey of sensory indulgence and relaxation. Whether
            you seek the perfect gift or a personal indulgence, our range offers
            something for every connoisseur of fine living. Visit our online
            boutique to discover the allure of sandalwood in its various forms
            at www.sandaura.co.in
          </p>
        </div>
        {/* <div className="info-prod-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default OurProduct;

import React from "react";
import "./Offers.css";
import servicesBanner from "../../Assets/Services_Banner__Web.png";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";

export const ServicesBanner = () => {
  const navigate = useNavigate();

  const handleBannerClick = () => {
    window.scrollTo(0, 0);
    navigate("/Services");
  };

  return (
    <div
      className="Service-banner-container"
      onClick={handleBannerClick}
      style={{ cursor: "pointer" }}
    >
      <Image src={servicesBanner} alt="Services Banner" fluid />
    </div>
  );
};

export default ServicesBanner;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/i18n.js";
import App from "./App";
import Modal from "react-modal";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Context/Auth";
import { CartProvider } from "./Context/Cart";
import ShopContextProvider from "./Context/ShopContext";
import { ThemeProvider } from "./Components/Layout/Theme.jsx";
import { WishlistProvider } from "./Context/WishlistContext.jsx";


Modal.setAppElement("#root");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider>
      <AuthProvider>
        <CartProvider>
          <BrowserRouter>
            <ShopContextProvider>
              <WishlistProvider>
                <App />
              </WishlistProvider>
            </ShopContextProvider>
          </BrowserRouter>
        </CartProvider>
      </AuthProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
